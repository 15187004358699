import { TABLE_COMPOSITION_FIELD_TYPE_LABELS } from '../../../../../pages/CCI/components/RightPanel/RightPanel_components/ChecklistGptTab/inputs/TableCompositionInput'
import { _Column, _Row } from '../SpreadsheetEditor'
import { dropRightWhile } from 'lodash'
import GC from '@mescius/spread-sheets'

// functions

export const addTypeComment = (sheet: GC.Spread.Sheets.Worksheet, columnIndex: number, type: string | undefined) => {
  if (!type) return

  const fieldTypeLabel = TABLE_COMPOSITION_FIELD_TYPE_LABELS.get(type)

  if (!fieldTypeLabel) return

  sheet.comments.add(0, columnIndex, fieldTypeLabel)

  const comment = sheet.comments.get(0, columnIndex)

  if (comment) {
    comment.autoSize(true)
    comment.dynamicSize(false)
    comment.locked(true)
    comment.lockText(true)
    comment.padding(new GC.Spread.Sheets.Comments.Padding(4, 4, 4, 4))
  }
}

const compareSpreadsheetData = (currentColumns: _Column[], currentRows: _Row[], originalColumns: _Column[], originalRows: _Row[]): boolean => {
  if (currentColumns.length !== originalColumns.length || currentRows.length !== originalRows.length) return false

  // Compare columns.
  for (let i = 0; i < currentColumns.length; i++) {
    if (currentColumns[i].name !== originalColumns[i].name) return false
  }

  // Compare rows.
  for (let i = 0; i < currentRows.length; i++) {
    const currentRow = currentRows[i]
    const originalRow = originalRows[i]

    if (Object.keys(currentRow).length !== Object.keys(originalRow).length) return false

    for (const key in currentRow) {
      if (currentRow[key] !== originalRow[key]) return false
    }
  }

  return true
}

const getEmptyColumnHeaderIndices = (sheet: GC.Spread.Sheets.Worksheet, columnCount: number, rowCount: number): number[] => {
  // Identify columns with empty headers but non-empty cells.
  const emptyColumnHeaderIndices: number[] = []

  for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
    const headerValue = sheet.getValue(0, columnIndex)
    const hasEmptyHeader = headerValue === null || headerValue === undefined || headerValue === ''

    if (hasEmptyHeader) {
      // Check if any cell in this column has a value.
      for (let rowIndex = 1; rowIndex < rowCount; rowIndex++) {
        const cellValue = sheet.getValue(rowIndex, columnIndex)

        if (cellValue !== null && cellValue !== undefined && cellValue !== '') {
          emptyColumnHeaderIndices.push(columnIndex)

          break
        }
      }
    }
  }

  return emptyColumnHeaderIndices
}

export const getNonEmptyColumnCount = (sheet: GC.Spread.Sheets.Worksheet) => {
  const totalCols = sheet.getColumnCount()
  let lastNonEmptyColumnIndex = -1

  // Check each column for any non-empty cells
  for (let columnIndex = 0; columnIndex < totalCols; columnIndex++) {
    const rowCount = sheet.getRowCount()

    for (let row = 0; row < rowCount; row++) {
      const value = sheet.getValue(row, columnIndex)

      if (value !== null && value !== undefined && value !== '') {
        lastNonEmptyColumnIndex = columnIndex

        break
      }
    }
  }

  return lastNonEmptyColumnIndex + 1
}

const getRowData = (sheet: GC.Spread.Sheets.Worksheet, rowCount: number, validColumnIndices: number[], currentColumns: _Column[]): _Row[] => {
  // Get all row data – only include cells from columns with valid headers.
  const currentRows: _Row[] = []

  for (let rowIndex = 1; rowIndex < rowCount; rowIndex++) {
    const rowData: _Row = {}

    validColumnIndices.forEach((columnIndex, i) => {
      const cellValue = sheet.getValue(rowIndex, columnIndex)
      const key = currentColumns[i].name.toLowerCase().replace(/ /g, '_')

      rowData[key] = cellValue !== null && cellValue !== undefined ? cellValue : ''
    })

    currentRows.push(rowData)
  }

  return currentRows
}

export const getSpreadsheetData = (
  workbook: GC.Spread.Sheets.Workbook | null,
  columns: _Column[],
  rows: _Row[],
  onError: (message: string) => void
): { columns: _Column[]; emptyColumnHeaderIndices: number[]; isUnchanged: boolean; rows: _Row[] } | null => {
  if (!workbook) return null

  try {
    const sheet = workbook.getActiveSheet()
    const columnCount = sheet.getColumnCount()
    const rowCount = sheet.getRowCount()

    const { currentColumns, validColumnIndices } = getValidColumns(sheet, columnCount)

    const currentRows = getRowData(sheet, rowCount, validColumnIndices, currentColumns)

    const emptyColumnHeaderIndices = getEmptyColumnHeaderIndices(sheet, columnCount, rowCount)

    // Drop empty rows from the end.
    const trimmedRows = dropRightWhile(currentRows, (row: _Row) => !Object.entries(row).some(([, value]) => value !== ''))

    const isUnchanged = emptyColumnHeaderIndices.length === 0 && compareSpreadsheetData(currentColumns, trimmedRows, columns, rows || [])

    return { columns: currentColumns, emptyColumnHeaderIndices, isUnchanged, rows: trimmedRows }
  } catch (error) {
    onError('Error reading spreadsheet data')

    return null
  }
}

const getValidColumns = (sheet: GC.Spread.Sheets.Worksheet, columnCount: number): { currentColumns: _Column[]; validColumnIndices: number[] } => {
  // Get valid column headers – only include columns that have header values.
  const currentColumns: _Column[] = []
  const validColumnIndices: number[] = []

  for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
    const headerValue = sheet.getValue(0, columnIndex)

    if (headerValue !== null && headerValue !== undefined && headerValue !== '') {
      currentColumns.push({ name: headerValue.toString() })
      validColumnIndices.push(columnIndex)
    }
  }

  return { currentColumns, validColumnIndices }
}
