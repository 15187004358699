import { Chip } from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { FC } from 'react'

// types

type _SamplesCellProps = { totalSamples: number }

// components

export const SamplesCell: FC<_SamplesCellProps> = ({ totalSamples }) => <Chip label={totalSamples} sx={{ bgcolor: grey[200], fontSize: 12, ml: 'auto' }} />
