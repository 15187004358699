import { Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { BypassMatchingControl, BypassMatchingForm, CouldNotRunMatching, MatchingOrNonMatchingInfoIcon, NonMatchingData } from './DataMatching'
import { Column, useTable } from 'react-table'
import { DataPoint, DataPointAnnotations, Maybe } from '../../graphql/codegen/schemas'
import { KLARITY_BLUE } from '../../utils/styleUtils'
import { NavLink, useHistory } from 'react-router-dom'
import { Source } from './Source'
import { common, grey } from '@mui/material/colors'
import { size } from 'lodash'
import { theme } from '../../app'
import { useDocumentPageWrapperContext } from '../DocumentPageWrapper'
import { useOpening } from '@hoologic/use-opening'
import InfoIcon from '@mui/icons-material/Info'
import React, { FC, Fragment, useCallback, useMemo } from 'react'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'

// types

type _CellData = { metadata: _CellMetadata; reference_value: string | number | boolean }
type _CellMetadata = { mismatch: boolean; other_value: string | number | boolean; reason: string }
type _ColumnOrRowMetadata = { full_mismatch: boolean; reason: string }
type _Column<T extends object = {}> = Column<T> & { metadata: _ColumnOrRowMetadata }
type _Data<T extends object = {}> = { [key in keyof T]: _CellData } & { metadata: _ColumnOrRowMetadata }
type _DataTableMatchingFieldProps = { dataPoint: DataPoint }
type _ReactTable<T extends object = {}> = { columns: _Column<T>[]; data: _Data<T>[] }

// types

type _Table = {
  annotations?: Maybe<Maybe<DataPointAnnotations>[]>
  dataPoint: DataPoint
  document?: { id: string; name: string; type: string }
  id: string
  name: string
}

// constants

const FALLBACK_TABLE: _ReactTable = { columns: [], data: [] }

// components

export const DataTableMatchingField: FC<_DataTableMatchingFieldProps> = ({ dataPoint }) => {
  const { setActiveTableId } = useDocumentPageWrapperContext()

  const table: _ReactTable = useMemo(
    () => (dataPoint.value_data_table?.table_matching_result ? JSON.parse(dataPoint.value_data_table.table_matching_result) : FALLBACK_TABLE),
    [dataPoint.value_data_table?.table_matching_result]
  )

  const history = useHistory()
  const opening = useOpening()
  const { getTableBodyProps, getTableProps, headerGroups, prepareRow, rows } = useTable(table)

  const popperProps = useMemo(
    () => ({
      sx: { '.MuiTooltip-arrow': { color: common.white }, '.MuiTooltip-tooltip': { bgcolor: common.white, boxShadow: theme.shadows[3], color: common.black } }
    }),
    []
  )

  const tables: _Table[] = useMemo(
    () =>
      dataPoint.matching_children_data_points?.edges.map(edge => ({
        annotations: edge?.node?.annotations,
        dataPoint: edge?.node as DataPoint,
        ...(edge?.node?.document && {
          document: { id: edge.node.document.id, name: edge.node.document.name, type: edge.node.document.document_type?.name as string }
        }),
        id: edge?.node?.id as string,
        name: edge!.node?.data_point_field?.name as string
      })) || [],
    [dataPoint.matching_children_data_points]
  )

  const renderFirstCell = useCallback(
    row => (
      <Box sx={{ alignItems: 'center', display: 'flex', gap: 0.5 }}>
        {row.index + 1}

        {row.original.metadata.full_mismatch && (
          <Tooltip arrow placement="right" title={row.original.metadata.reason}>
            <InfoIcon color="warning" sx={{ fontSize: 16 }} />
          </Tooltip>
        )}
      </Box>
    ),
    []
  )

  const renderCell = useCallback(
    cell => {
      const isMismatch = cell.value.metadata.mismatch && !cell.column.metadata.full_mismatch && !cell.row.original.metadata.full_mismatch

      const title = isMismatch && (
        <Box sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
            {tables[0].name}

            {tables[0].document ? ` – ${tables[0].document.type}` : <Source {...tables[0].dataPoint.data_point_field} />}
          </Typography>

          {tables[0].document && <Typography sx={{ color: grey[600], fontSize: 11 }}>{tables[0].document.name}</Typography>}

          <Typography sx={{ fontSize: 16, fontWeight: 600, mt: 0.5 }}>{cell.value.reference_value}</Typography>

          <Typography sx={{ fontSize: 13, fontWeight: 600, mt: 2 }}>
            {tables[1].name}

            {tables[1].document ? ` – ${tables[1].document.type}` : <Source {...tables[1].dataPoint.data_point_field} />}
          </Typography>

          {tables[1].document && <Typography sx={{ color: grey[600], fontSize: 11 }}>{tables[1].document.name}</Typography>}

          <Box sx={{ alignItems: 'center', display: 'flex', gap: 0.5, mt: 0.5 }}>
            <Typography sx={{ fontSize: 16, fontWeight: 600 }}>{cell.value.metadata.other_value}</Typography>

            <Tooltip arrow placement="right" title={cell.value.metadata.reason}>
              <InfoIcon color="warning" sx={{ fontSize: 16 }} />
            </Tooltip>
          </Box>
        </Box>
      )

      return (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 0.5 }}>
          {cell.value.reference_value}

          {isMismatch && (
            <Tooltip
              PopperProps={{
                sx: {
                  '.MuiTooltip-arrow': { color: common.white },
                  '.MuiTooltip-tooltip': { bgcolor: common.white, boxShadow: theme.shadows[3], color: common.black }
                }
              }}
              placement="right"
              title={title}
            >
              <InfoIcon color="warning" sx={{ fontSize: 16 }} />
            </Tooltip>
          )}
        </Box>
      )
    },
    [tables]
  )

  const renderHeader = useCallback(
    header => (
      <Box sx={{ alignItems: 'center', display: 'flex', gap: 0.5 }}>
        {header.name}

        {header.metadata.full_mismatch && (
          <Tooltip arrow placement="right" title={header.metadata.reason}>
            <InfoIcon color="warning" sx={{ fontSize: 16 }} />
          </Tooltip>
        )}
      </Box>
    ),
    []
  )

  const renderTitle = useCallback(
    ({ dataPoint, document }: _Table) =>
      document ? (
        <Box sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 13, fontWeight: 600 }}>{document.type}</Typography>

          <Typography sx={{ color: grey[600], fontSize: 11 }}>{document.name}</Typography>
        </Box>
      ) : (
        <Box sx={{ p: 1, pl: 0 }}>
          <Source {...dataPoint.data_point_field} />
        </Box>
      ),
    []
  )

  return (
    <>
      <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 1.5, mt: -0.75, mx: 1 }}>
        {tables.map((table, index, array) => {
          const annotationId = table.annotations?.[0]?.annotations?.[0]?.id

          return (
            <Fragment key={table.id}>
              <Tooltip PopperProps={popperProps} placement="top" title={renderTitle(table)}>
                {table.document ? (
                  <NavLink
                    onClick={() => setActiveTableId(table.id)}
                    to={`${history.location.pathname}?documentTab=${table.document.id}${annotationId ? `#highlight-${annotationId}` : ''}`}
                  >
                    {table.name}
                  </NavLink>
                ) : (
                  <Box component="span" onClick={() => setActiveTableId(table.id)} sx={{ color: KLARITY_BLUE, cursor: 'pointer' }}>
                    {table.name}
                  </Box>
                )}
              </Tooltip>

              <Box component="span" sx={{ ml: 0.5, '& svg': { height: 16, position: 'relative', top: 3, width: 16 } }}>
                <MatchingOrNonMatchingInfoIcon dataPoint={table.dataPoint} parentDataPoint={dataPoint} placement="bottom-start" />
              </Box>

              {index < size(array) - 1 && <UnfoldMoreIcon sx={{ mt: -1, mx: 0.75, position: 'relative', top: 7, transform: 'rotate(90deg)' }} />}
            </Fragment>
          )
        })}
      </Typography>

      {dataPoint.value_bool === null && <CouldNotRunMatching />}

      {dataPoint.value_bool === false && <NonMatchingData />}

      <Box sx={{ maxWidth: '100%', overflowX: 'scroll' }}>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              // eslint-disable-next-line react/jsx-key
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                <TableCell />

                {headerGroup.headers.map(header => (
                  // eslint-disable-next-line react/jsx-key
                  <TableCell {...header.getHeaderProps()} sx={{ fontWeight: 600, lineHeight: 1.2, p: 1, verticalAlign: 'top' }}>
                    {renderHeader(header)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)

              return (
                // eslint-disable-next-line react/jsx-key
                <TableRow {...row.getRowProps()} sx={dataPoint.value_bool !== false ? { ':last-of-type td': { borderBottom: 0, pb: 0 } } : {}}>
                  <TableCell sx={{ color: grey[500], p: 1, verticalAlign: 'top' }}>{renderFirstCell(row)}</TableCell>

                  {row.cells.map(cell => (
                    // eslint-disable-next-line react/jsx-key
                    <TableCell {...cell.getCellProps()} sx={{ p: 1, verticalAlign: 'top' }}>
                      {renderCell(cell)}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Box>

      {dataPoint.value_bool === false && (
        <>
          <BypassMatchingControl opening={opening} />

          <BypassMatchingForm dataPoint={dataPoint} opening={opening} />
        </>
      )}
    </>
  )
}
