import { Chip } from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { FC } from 'react'

// types

type _CorrectCellProps = { correctSamples: number }

// components

export const CorrectCell: FC<_CorrectCellProps> = ({ correctSamples }) => <Chip label={correctSamples} sx={{ bgcolor: grey[200], fontSize: 12, ml: 'auto' }} />
