import { Box, Typography } from '@mui/material'
import { CciDealDataPointFieldsDocument, MatchingChildrenDataPointFieldsDocument, useEditDataPointFieldMutation } from '../../../../../graphql/codegen/hooks'
import { CollisionTypeLabels } from '../../RightPanel/RightPanel_components/ChecklistTab/SharedInputs/CollisionTypeInput'
import { DELETE, getFieldTypeLabel } from '../../../../../utils/cci'
import { DisplayYesOrNo } from '../../Utils'
import { _SelectInput as _ResolutionStrategy } from '../../RightPanel/RightPanel_components/ChecklistTab/SharedInputs/MatchFields'
import { formatDisplayedExtractionPath } from '../../RightPanel/RightPanel_components/ChecklistTab/Accordions/ExternalExtractionConfigurationAccordion/ExternalExtractionConfigurationAccordion'
import { formatFieldSemantics, formatMentions } from '../../../../../utils/commentUtils'
import { isEmpty } from 'lodash'
import { useCciMainContext } from '../../../CCI_Main'
import { useTranslation } from 'react-i18next'
import Button from '../../../../../components/Button'
import React, { FC, ReactNode, useEffect } from 'react'
import css from '../style.module.scss'

// types

type _FieldProps = { isBooleanInput?: boolean; label: string; value: unknown; valueFormatter?: (value: any) => ReactNode }
type _OptionsProps = { deletedOptions: any[]; options: any[] }
type _ReviewEditsProps = { closeModal: () => void; modalContent: { dataPointId?: string; dataPointName?: string; values?: any } }

// constants

const BOOLEAN_FIELD_LIST = ['default_field_on_dashboard', 'display_accounting_impact', 'display_annotations', 'display_if_empty', 'display_on_checklist']

// components

const Field: FC<_FieldProps> = ({ isBooleanInput, label, value, valueFormatter }) =>
  // Filter out "falsy" values, but allow explicit `false` values to be displayed for boolean inputs.
  !value && value !== false ? null : (
    <div className={css.reviewDiv}>
      <h4>{label}:</h4>

      {isBooleanInput ? <DisplayYesOrNo value={value} /> : valueFormatter ? valueFormatter(value) : <p>{value}</p>}
    </div>
  )

const Options: FC<_OptionsProps> = ({ deletedOptions, options }) =>
  isEmpty(options) ? null : (
    <div className={css.reviewDiv}>
      <h4>Options:</h4>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {options.map((item: any, index: number) =>
          !item?.newValue ? null : (
            <div className={css.reviewOptions} key={`${item?.currentValue}-${item?.newValue}-${index}`}>
              <p style={{ width: '100%' }}>
                {item?.currentValue
                  ? item?.newValue !== DELETE && `${item?.currentValue} has been renamed to: ${item?.newValue}`
                  : `New Option: ${item?.newValue}`}
              </p>
            </div>
          )
        )}

        {deletedOptions?.length > 0 && (
          <>
            <h5 style={{ textAlign: 'left' }}>Deleted Options:</h5>

            {deletedOptions.map((item: any, index: number) => (
              <p key={`${item?.currentValue}-${index}`} style={{ width: '100%', textAlign: 'right' }}>
                {item?.currentValue}
              </p>
            ))}
          </>
        )}
      </div>
    </div>
  )

export const ReviewEdits: FC<_ReviewEditsProps> = ({ closeModal, modalContent }) => {
  const { dataPointId, dataPointName, values } = modalContent || {}

  const {
    collision_type: collisionType,
    default_field_on_dashboard: defaultFieldOnDashboard,
    default_value: defaultValue,
    deleted_options: deletedOptions,
    description,
    display_accounting_impact: displayAccountingImpact,
    display_annotations: displayAnnotations,
    display_if_empty: displayIfEmpty,
    display_on_checklist: displayOnChecklist,
    extraction_logic_path: extractionLogicPath,
    field_semantics: fieldSemantics,
    field_type: fieldType,
    group,
    matching_prompt_description: matchingPromptDescription,
    name,
    options,
    resolution_strategy: resolutionStrategy,
    value_format: valueFormat
  } = values || {}

  const {
    fieldOverlay,
    selectedItem,
    setActiveComponent,
    setMessageTitle,
    setModalError,
    setModalLoading,
    setModalLoadingMessage,
    setModalSuccess,
    setSelectedItem
  } = useCciMainContext()

  const [editDataPointFieldMutation, { error: mutationError, loading: isMutationLoading }] = useEditDataPointFieldMutation({
    onCompleted: response => {
      fieldOverlay.close()
      setActiveComponent(null)
      setMessageTitle(response.edit_data_point_field!.data_point_field!.name)
      setModalSuccess('Field successfully updated.')
      setSelectedItem('')
    },
    refetchQueries: [{ query: CciDealDataPointFieldsDocument }, { query: MatchingChildrenDataPointFieldsDocument }]
  })

  const { t } = useTranslation()

  // functions

  const formatBooleanInputs = () =>
    BOOLEAN_FIELD_LIST.reduce((changedFields, field) => {
      if (selectedItem?.[field] !== values?.[field]) {
        changedFields[field] = values?.[field]
      }

      return changedFields
    }, {} as Record<string, boolean>)

  const formatOptions = () => {
    // remove deleted new options
    const formattedOptions = options?.filter((item: any) => !(!item?.currentValue && item?.newValue === DELETE)) || []
    const formattedDeletedOptions = deletedOptions?.filter((item: any) => !(!item?.currentValue && item?.newValue === DELETE)) || []

    const combinedOptions = [...formattedOptions, ...formattedDeletedOptions]

    return combinedOptions?.length ? JSON.stringify(combinedOptions) : undefined
  }

  const handleSubmit = () => {
    const localValues = { ...values }

    if (localValues.field_semantics) {
      localValues.field_semantics = formatMentions(localValues.field_semantics)
    }

    const options = formatOptions()
    delete localValues.options
    delete localValues.deleted_options

    const booleans = formatBooleanInputs()
    delete localValues.default_field_on_dashboard
    delete localValues.display_accounting_impact
    delete localValues.display_annotations
    delete localValues.display_if_empty
    delete localValues.display_on_checklist

    editDataPointFieldMutation({ variables: { data_point_field_id: dataPointId, options, ...localValues, ...booleans } })
  }

  // effects

  useEffect(() => {
    if (isMutationLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Updating Field…')
    } else {
      setModalLoading(false)
      setModalLoadingMessage('')
    }
  }, [isMutationLoading, setModalLoading, setModalLoadingMessage])

  useEffect(() => {
    if (mutationError) {
      setMessageTitle('Error updating field:')
      setModalError(mutationError)
    }
  }, [mutationError, setMessageTitle, setModalError])

  // render

  return (
    <>
      <h5 style={{ textAlign: 'center', marginBottom: '32px' }}>{dataPointName}</h5>

      <Field label="Field Name" value={name} />

      <Field label="Section" value={group} />

      <Field label="Description" value={fieldSemantics} valueFormatter={formatFieldSemantics} />

      <Field label="Field Type" value={fieldType} valueFormatter={getFieldTypeLabel} />

      <Field label="Extraction Path" value={extractionLogicPath} valueFormatter={formatDisplayedExtractionPath} />

      <Options deletedOptions={deletedOptions} options={options} />

      <Field label="Value Format" value={valueFormat && valueFormat !== DELETE ? valueFormat : undefined} />

      <Field label="Default Value" value={defaultValue} />

      <Field label="Collision Type" value={collisionType} valueFormatter={value => CollisionTypeLabels[value as keyof typeof CollisionTypeLabels]} />

      <Field
        label="Resolution Strategy"
        value={!isEmpty(resolutionStrategy) ? resolutionStrategy : undefined}
        valueFormatter={value => (
          <Box sx={{ textAlign: 'right' }}>
            {value.map((resolutionStrategy: _ResolutionStrategy, index: number) => (
              <Box key={index}>{resolutionStrategy}</Box>
            ))}
          </Box>
        )}
      />

      <Field label="Match Prompt" value={matchingPromptDescription} valueFormatter={value => <Typography sx={{ fontSize: 10 }}>{value}</Typography>} />

      <Field label="Tooltip Text" value={description} />

      <Field isBooleanInput label="Always hide this field" value={typeof displayOnChecklist === 'boolean' ? !displayOnChecklist : undefined} />

      <Field isBooleanInput label="Hide this field only when it has no value" value={typeof displayIfEmpty === 'boolean' ? !displayIfEmpty : undefined} />

      <Field isBooleanInput label="Display annotation highlights" value={typeof displayAnnotations === 'boolean' ? displayAnnotations : undefined} />

      <Field isBooleanInput label="Hide accounting impact button" value={typeof displayAccountingImpact === 'boolean' ? !displayAccountingImpact : undefined} />

      <Field
        isBooleanInput
        label={`Display as default column in ${t('Deals')} and Documents table views`}
        value={typeof defaultFieldOnDashboard === 'boolean' ? defaultFieldOnDashboard : undefined}
      />

      {extractionLogicPath && (
        <Typography sx={{ my: 2 }} variant="body2">
          {`Note: Updating the extraction path will initiate Klarity's value extraction process. Values for this checklist field will be updated across all
          ${t('deals')}
          while the process is running.`}
        </Typography>
      )}

      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} variant="secondary">
          Cancel
        </Button>

        <Button onClick={handleSubmit}>Submit</Button>
      </div>
    </>
  )
}
