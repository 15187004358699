import { Chip } from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { FC } from 'react'

// types

type _InReviewCellProps = { inReviewSamples: number }

// components

export const InReviewCell: FC<_InReviewCellProps> = ({ inReviewSamples }) => (
  <Chip label={inReviewSamples} sx={{ bgcolor: grey[200], fontSize: 12, ml: 'auto' }} />
)
