import { FORMULA_PREFIX } from '../constants'
import { MutableRefObject } from 'react'
import { _Column } from '../SpreadsheetEditor'
import { addTypeComment, getNonEmptyColumnCount } from './dataManagement'

import GC from '@mescius/spread-sheets'

// functions

const handleColumnHeaderChange = (sheet: GC.Spread.Sheets.Worksheet, columns: _Column[], row: number, column: number) => {
  if (row !== 0) return

  const existingComment = sheet.comments.get(0, column)

  if (existingComment) sheet.comments.remove(0, column)

  const value = sheet.getValue(row, column)

  if (!value?.toString().trim()) return

  const matchingColumn = columns.find(column => column.name.trim().toLowerCase() === value.toString().trim().toLowerCase())

  addTypeComment(sheet, column, matchingColumn?.type)
}

export const setCellSelectionHandler = (sheet: GC.Spread.Sheets.Worksheet, setCellInfo: (info: any) => void) => {
  sheet.bind(GC.Spread.Sheets.Events.SelectionChanged, (_: unknown, args: GC.Spread.Sheets.ISelectionChangedEventArgs) => {
    const selection = args.newSelections[0]

    if (selection) {
      const { col: column, row } = selection
      const formula = sheet.getFormula(row, column)
      const value = sheet.getText(row, column)

      setCellInfo({ column, formula: formula ? `=${formula}` : undefined, row, value })
    }
  })
}

export const setChangeEventHandlers = (
  sheet: GC.Spread.Sheets.Worksheet,
  onChange: (() => void) | undefined,
  previousColumnCount: MutableRefObject<number>,
  columns: _Column[]
) => {
  if (!onChange) return

  // Basic events:

  const basicChangeEvents = [GC.Spread.Sheets.Events.ClipboardPasted, GC.Spread.Sheets.Events.DragDropBlockCompleted, GC.Spread.Sheets.Events.RowChanged]

  basicChangeEvents.forEach(event => sheet.bind(event, onChange))

  // Column header events:

  const columnHeaderChangeEvents = [GC.Spread.Sheets.Events.RangeChanged, GC.Spread.Sheets.Events.ValueChanged]

  columnHeaderChangeEvents.forEach(event => {
    sheet.bind(event, (_: unknown, args: GC.Spread.Sheets.IRangeChangedEventArgs | GC.Spread.Sheets.IValueChangedEventArgs) => {
      const { col: column, row } = args

      if (row === 0) handleColumnHeaderChange(sheet, columns, row, column)

      onChange()
    })
  })

  // Miscellaneous events:

  sheet.bind(GC.Spread.Sheets.Events.ColumnChanging, () => {
    previousColumnCount.current = getNonEmptyColumnCount(sheet)
  })

  sheet.bind(GC.Spread.Sheets.Events.ColumnChanged, () => {
    const currentColumnCount = getNonEmptyColumnCount(sheet)

    if (currentColumnCount !== previousColumnCount.current) onChange()
  })

  sheet.bind(GC.Spread.Sheets.Events.EditEnding, (_: unknown, args: GC.Spread.Sheets.IEditEndingEventArgs) => {
    const { col: column, editingText, row } = args
    const isFormula = typeof editingText === 'string' && editingText.startsWith(FORMULA_PREFIX)

    // Allow SpreadJS to auto-format formula-derived cell values, but otherwise retain the default text format.
    sheet.getCell(row, column).formatter(isFormula ? 'General' : '@')
  })
}
