import { Box, Tooltip, Typography } from '@mui/material'
import { TooltipInfo } from '../TooltipInfo'
import { amber, common } from '@mui/material/colors'
import { isEmpty } from 'lodash'
import { useCciMainContext } from '../../../../../CCI_Main'
import { useChecklistConfigContext } from '../../../../ChecklistConfigProvider'
import { useFieldValuesContext } from '../FieldValuesProvider'
import React, { FC, useEffect, useMemo, useState } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import css from './style.module.scss'

// types

export type _ExtractionMethodOption = { label: string; value: string | undefined }

// constants

const LABEL = 'AI Provider'

const PLACEHOLDER = 'Select an AI provider'

// components

export const ExtractionMethodInput: FC = () => {
  const { isModalOpen, selectedItem, setIsModalOpen, setModalLoading, setModalLoadingMessage } = useCciMainContext()

  const {
    actionTypeMap,
    createExtractionConfiguration,
    editExtractionConfiguration,
    extractionMethodId,
    extractionMethodList,
    isCreateView,
    isEditView,
    setExtractionConfigurationChanges
  } = useChecklistConfigContext()

  const { fieldValues } = useFieldValuesContext()
  const [selectedExtractionMethod, setSelectedExtractionMethod] = useState<_ExtractionMethodOption | null>(null)

  const actionType = useMemo(
    () => actionTypeMap[fieldValues?.action_type_id || selectedItem?.action_type?.id] || null,
    [actionTypeMap, fieldValues?.action_type_id, selectedItem]
  )

  const initialExtractionConfiguration = useMemo(() => JSON.parse(selectedItem?.extraction_method_instances_config?.[0] || '{}'), [selectedItem])

  const validOptionList = useMemo(
    () =>
      actionType
        ? extractionMethodList.filter(item => actionType.extraction_methods?.edges.some((edge: any) => edge.node.id === item.value))
        : extractionMethodList,
    [actionType, extractionMethodList]
  )

  const isExtractionMethodListMisconfigured = isEmpty(validOptionList) && (isCreateView || !isEmpty(initialExtractionConfiguration))

  const displayedOptionList = useMemo(
    () =>
      isExtractionMethodListMisconfigured && actionType?.default_extraction_method
        ? [{ label: actionType.default_extraction_method.display_name, value: actionType.default_extraction_method.id }]
        : validOptionList,
    [actionType, isExtractionMethodListMisconfigured, validOptionList]
  )

  const handleChange = ({ label, value }: _ExtractionMethodOption) => {
    setSelectedExtractionMethod({ label, value })
    setExtractionConfigurationChanges({ extractionMethodId: value, ...(isEditView && isEmpty(initialExtractionConfiguration) && { isEnabled: true }) })

    if (isEditView && value && value !== initialExtractionConfiguration.extraction_method_id) {
      setIsModalOpen(true)
      setModalLoading(true)
      setModalLoadingMessage('Saving…')

      if (isEmpty(initialExtractionConfiguration)) {
        createExtractionConfiguration(selectedItem.id)
      } else {
        editExtractionConfiguration()
      }
    }
  }

  // effects

  useEffect(() => {
    // Initialize the selected extraction method.
    if (!selectedExtractionMethod) {
      if (isExtractionMethodListMisconfigured && actionType?.default_extraction_method) return setSelectedExtractionMethod(displayedOptionList[0])

      if (!isEmpty(initialExtractionConfiguration)) {
        setSelectedExtractionMethod({
          label: initialExtractionConfiguration.extraction_method_name,
          value: initialExtractionConfiguration.extraction_method_id
        })
      } else if (actionType && isCreateView && !isModalOpen) {
        // Check `!isModalOpen` to avoid overwriting user changes in re-renders during 2-stage field-creation process. See: ExtractionAutomationSwitch.tsx
        const defaultMethod = validOptionList.find(({ value }) => value === actionType.default_extraction_method?.id)

        setSelectedExtractionMethod(defaultMethod || null)
      }
    }
  }, [
    actionType,
    displayedOptionList,
    extractionMethodId,
    initialExtractionConfiguration,
    isCreateView,
    isExtractionMethodListMisconfigured,
    isModalOpen,
    validOptionList,
    selectedExtractionMethod,
    selectedItem
  ])

  useEffect(() => {
    if (selectedExtractionMethod?.value && selectedExtractionMethod.value !== initialExtractionConfiguration.extraction_method_id) {
      setExtractionConfigurationChanges({ extractionMethodId: selectedExtractionMethod.value })
    }
  }, [initialExtractionConfiguration, selectedExtractionMethod, setExtractionConfigurationChanges])

  // render

  return (
    <Box className={css.inputWrapper} sx={{ mt: 1 }}>
      <Typography component="label" htmlFor="extraction-method-type-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        {LABEL}

        {isExtractionMethodListMisconfigured ? (
          <Tooltip arrow placement="right" title="The AI Provider list has potentially been misconfigured for this field’s action type.">
            <WarningAmberIcon sx={{ color: amber[700], fontSize: 16, ml: 0.5, mt: 0.25, position: 'absolute' }} />
          </Tooltip>
        ) : (
          isEditView && <TooltipInfo tooltipTitle="Changes are saved automatically after selecting an option." />
        )}
      </Typography>

      <SelectInput
        id="extraction-method-type-input"
        isDisabled={isExtractionMethodListMisconfigured}
        onChange={handleChange}
        options={displayedOptionList}
        placeholder={selectedExtractionMethod ? selectedExtractionMethod.label : PLACEHOLDER}
        styles={{ singleValue: { color: common.black, opacity: 1 } }}
        value={selectedExtractionMethod}
      />
    </Box>
  )
}
