import { useContextInit } from '../../../../../../hooks/useContextInit'
import React, { FC, ReactNode, createContext, useCallback, useMemo, useState } from 'react'

// types

type _FieldValuesContext = {
  fieldValues?: { [key: string]: any }
  setFieldValues: (values: { [key: string]: any } | undefined) => void
  updateFieldValue: (fieldName: string, value: any) => void
}

type _FieldValuesProviderProps = { children: ReactNode }

// context

const FieldValuesContext = createContext<_FieldValuesContext | null>(null)

// hooks

export const useFieldValuesContext = () => useContextInit(FieldValuesContext)

// components

export const FieldValuesProvider: FC<_FieldValuesProviderProps> = ({ children }) => {
  const [fieldValues, setFieldValues] = useState<{ [key: string]: any } | undefined>()

  const updateFieldValue = useCallback((fieldName: string, value: any) => {
    if (fieldName && !value && value !== false) {
      setFieldValues(previous => {
        const updatedObject = { ...previous }

        delete updatedObject[fieldName]

        return updatedObject
      })
    } else {
      switch (fieldName) {
        case '':
        case undefined:
        case null:
          console.error('ConfigureField updateFieldValue error: Must provide a valid option')
          return
        case 'deleted_options':
          setFieldValues(previous => ({ ...previous, deleted_options: [...value] }))
          return
        case 'options':
          setFieldValues(previous => ({ ...previous, options: [...value] }))
          return
        default:
          setFieldValues(previous => ({ ...previous, [fieldName]: value }))
      }
    }
  }, [])

  const context = useMemo<_FieldValuesContext>(() => ({ fieldValues, setFieldValues, updateFieldValue }), [fieldValues, updateFieldValue])

  return <FieldValuesContext.Provider value={context}>{children}</FieldValuesContext.Provider>
}
