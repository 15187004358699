import { ActionTypeCell } from './cells/ActionTypeCell'
import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { CONFIG_HEADER_HEIGHT } from '../Header'
import { Column, HeaderGroup, Row, useTable } from 'react-table'
import { CorrectCell } from './cells/CorrectCell'
import { DescriptionCell } from './cells/DescriptionCell'
import { DndContext, DragEndEvent, DragMoveEvent, DragOverlay, DragStartEvent, closestCenter, useDraggable, useDroppable } from '@dnd-kit/core'
import { FieldNameCell } from './cells/FieldNameCell'
import { FieldOverlay } from './FieldOverlay'
import { FieldTypeCell } from './cells/FieldTypeCell'
import { HEADER_HEIGHT, Z_INDEX_DRAG } from '../../../../utils/styleUtils'
import { InReviewCell } from './cells/InReviewCell'
import { SamplesCell } from './cells/SamplesCell'
import { StatusCell } from './cells/StatusCell'
import { SystemSourceCell } from './cells/SystemSourceCell'
import { _Row, useChecklistConfigContext } from '../ChecklistConfigProvider'
import { arrayMove } from '@dnd-kit/sortable'
import { common, grey } from '@mui/material/colors'
import { groupDataPointFields } from '../../../../utils/dataPointUtils'
import { isEmpty, isEqual, size, sortBy } from 'lodash'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { useCciMainContext } from '../../CCI_Main'
import { useContextInit } from '../../../../hooks/useContextInit'
import { useRefCallback } from '../../../../hooks/useRefCallback'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { FC, RefObject, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'

// types

type _ChecklistTableContext = {
  areMultipleGroups: boolean
  firstRowRefCallback: (node: HTMLTableRowElement) => void
  prepareRow: (row: Row<_Row>) => void
  reorderLine: number | null
  rows: Row<_Row>[]
  top: number
}

type _FieldRowProps = { fieldId: string; group: string; row: Row<_Row> }
type _GroupAndFieldsRowsProps = { groupName: string }
type _GroupRowProps = { groupName: string }
type _HeaderRowProps = { headerGroup: HeaderGroup<_Row> }

// constants

const CELL_SX = { bgcolor: common.white, borderRight: `1px solid ${grey[300]}`, height: 49, py: 1 } as const

const SAMPLES_COLUMNS = ['Samples:', 'In Review', 'Correct']

const COLUMNS: Column<_Row>[] = [
  {
    Header: 'Field Name',
    accessor: 'fieldNameColumn',
    Cell: ({ value: { description, id, isDeleted, name, status } }) => (
      <FieldNameCell description={description} id={id} isDeleted={isDeleted} name={name} status={status} />
    )
  },
  { Header: 'Status', accessor: 'statusColumn', Cell: ({ value: { mainStatus } }) => <StatusCell mainStatus={mainStatus} /> },
  {
    Header: 'System Source',
    accessor: 'systemSourceColumn',
    Cell: ({ value: { externalSource, source } }) => <SystemSourceCell externalSource={externalSource} source={source} />
  },
  {
    Header: SAMPLES_COLUMNS[0],
    accessor: 'samplesColumn',
    Cell: ({ value: { totalSamples } }) => <SamplesCell totalSamples={totalSamples} />
  },
  {
    Header: SAMPLES_COLUMNS[1],
    accessor: 'inReviewColumn',
    Cell: ({ value: { inReviewSamples } }) => <InReviewCell inReviewSamples={inReviewSamples} />
  },
  {
    Header: SAMPLES_COLUMNS[2],
    accessor: 'correctColumn',
    Cell: ({ value: { correctSamples } }) => <CorrectCell correctSamples={correctSamples} />
  },
  {
    Header: 'Field Type',
    accessor: 'fieldTypeColumn',
    Cell: ({ value: { fieldType } }) => <FieldTypeCell fieldType={fieldType} />
  },
  {
    Header: 'Action Type',
    accessor: 'actionTypeColumn',
    Cell: ({ value: { actionTypeId } }) => <ActionTypeCell actionTypeId={actionTypeId} />
  },
  {
    Header: 'Description',
    accessor: 'descriptionColumn',
    Cell: ({ value: { description } }) => <DescriptionCell description={description} />
  }
] as const

const FIRST_CELL_SX = {
  bgcolor: common.white,
  borderRight: `2px solid ${grey[300]}`,
  height: 49,
  left: 0,
  pl: 6.25,
  position: 'sticky',
  py: 1,
  zIndex: 2
} as const

// context

const ChecklistTableContext = createContext<_ChecklistTableContext | null>(null)

// functions

const calculateReorderPosition = (event: DragEndEvent, areAllGroupsCollapsed: boolean, boxRef: RefObject<HTMLTableElement>) => {
  const { active, over } = event

  if (!active.rect.current.translated || !over) return null

  const dropRect = over.rect
  const dragCenterY = active.rect.current.translated.top + active.rect.current.translated.height / 2
  const isPlacedBefore = dragCenterY < dropRect.top + dropRect.height / 2

  if (!areAllGroupsCollapsed && over.data.current?.type === 'group') return { isPlacedBefore: false, reorderLine: null }

  return {
    isPlacedBefore,
    reorderLine: (isPlacedBefore ? dropRect.top : dropRect.bottom) - HEADER_HEIGHT - CONFIG_HEADER_HEIGHT + (boxRef.current?.scrollTop || 0)
  }
}

// hooks

const useChecklistTableContext = () => useContextInit(ChecklistTableContext)

// components

export const Checklist: FC = () => {
  const { fieldOverlay, setDocumentTypesField } = useCciMainContext()

  const { areAllGroupsCollapsed, fieldsData, isBusy, isGroupCollapsedMap, reorderField, reorderGroup, setIsGroupCollapsedMap } = useChecklistConfigContext()

  const boxRef = useRef<HTMLTableElement>(null)
  const [dragOverlay, setDragOverlay] = useState<string | null>(null)
  const [fields, setFields] = useState<_Row[]>([])
  const [firstRowElement, firstRowRefCallback] = useRefCallback()
  const [groupNames, setGroupNames] = useState<string[]>([])
  const [reorderLine, setReorderLine] = useState<number | null>(null)
  const [top, setTop] = useState(0)

  useMemo(
    () =>
      setFields(
        fieldsData?.cci_deal_data_point_fields
          ?.map(field => ({
            actionTypeColumn: { actionTypeId: field?.action_type?.id! },
            correctColumn: { correctSamples: field?.correct_samples! },
            descriptionColumn: { description: field?.field_semantics! },
            fieldTypeColumn: { fieldType: field?.field_type! },
            fieldNameColumn: {
              description: field?.description!,
              id: field?.id!,
              isDeleted: field?.is_deleted!,
              name: field?.name!,
              status: field?.main_status!
            },
            group: field?.group!,
            groupPriority: field?.group_priority!,
            id: field?.id!,
            inReviewColumn: { inReviewSamples: field?.in_review_samples! },
            name: field?.name!,
            priority: field?.priority!,
            samplesColumn: { totalSamples: field?.total_samples! },
            statusColumn: { mainStatus: field?.main_status! },
            systemSourceColumn: { externalSource: field?.external_source!, source: field?.source! }
          }))
          .sort((a, b) =>
            a?.groupPriority! > b?.groupPriority! ? 1 : a?.groupPriority! === b?.groupPriority! ? (a?.priority! > b?.priority! ? 1 : -1) : -1
          ) || []
      ),
    [fieldsData]
  )

  const { getTableBodyProps, getTableProps, headerGroups, prepareRow, rows } = useTable({ columns: COLUMNS, data: fields })

  const areMultipleGroups = useMemo(() => size(groupNames) > 1, [groupNames])
  const firstRowElementHeight = useMemo(() => firstRowElement?.clientHeight || 0, [firstRowElement])

  useMemo(() => {
    const groups = groupDataPointFields(fieldsData?.cci_deal_data_point_fields || [])

    const current = sortBy(Object.keys(groups), key => groups[key][0]?.group_priority)

    setGroupNames(previous => (isEqual(current, previous) ? previous : current))
  }, [fieldsData])

  useMemo(() => {
    if (!firstRowElement) return

    setTop(firstRowElementHeight)
  }, [firstRowElement, firstRowElementHeight])

  useEffect(
    () => setDocumentTypesField(fieldsData?.cci_deal_data_point_fields?.find(field => field?.internal_mapping?.includes('document_type'))),
    [fieldsData?.cci_deal_data_point_fields, setDocumentTypesField]
  )

  useEffect(() => {
    if (isBusy || isEmpty(groupNames) || !isEmpty(isGroupCollapsedMap)) return

    setIsGroupCollapsedMap(groupNames.reduce((previous, current) => ({ ...previous, [current]: false }), {}))
  }, [groupNames, isBusy, isGroupCollapsedMap, setIsGroupCollapsedMap])

  const handleDragCancel = useCallback(() => {
    setReorderLine(null)

    if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
  }, [])

  const handleDragStart = useCallback(
    ({ active }: DragStartEvent) => setDragOverlay(areAllGroupsCollapsed ? (active.id as string) : fields.find(field => field.id === active.id)?.name!),
    [areAllGroupsCollapsed, fields]
  )

  const handleFieldDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      setReorderLine(null)

      if (!active || !over || active.id === over.id || over.data.current?.type === 'group') return

      const reorderPosition = calculateReorderPosition(event, areAllGroupsCollapsed, boxRef)
      if (!reorderPosition) return
      const { isPlacedBefore } = reorderPosition

      const activeIndex = fields.findIndex(field => field.id === active.id)
      const overIndex = fields.findIndex(field => field.id === over.id)

      const isDifferentGroup = fields[overIndex].group !== fields[activeIndex].group

      if (!isDifferentGroup && ((overIndex === activeIndex - 1 && !isPlacedBefore) || (overIndex === activeIndex + 1 && isPlacedBefore))) return // no-op cases

      const targetIndexBase = (isPlacedBefore ? overIndex : overIndex + 1) - (overIndex > activeIndex ? 1 : 0)

      const group = fields[overIndex].group
      const priority = fields[overIndex].priority + (isPlacedBefore ? -0.5 : 0.5)

      const reorderedFields = arrayMove(fields, activeIndex, targetIndexBase)

      // optimistic update

      reorderedFields[targetIndexBase] = { ...reorderedFields[targetIndexBase], group, priority }

      setFields(reorderedFields)

      const isFirstFieldInGroup = reorderedFields.findIndex(field => field.group === group) === targetIndexBase

      reorderField({
        variables: {
          data_point_field_id: active.id as string,
          ...(isFirstFieldInGroup ? { move_to_top_group: group } : { move_after_data_point_field_id: reorderedFields[targetIndexBase - 1].id })
        }
      }) // backend update
    },
    [areAllGroupsCollapsed, fields, reorderField]
  )

  const handleGroupDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      setReorderLine(null)

      if (!active || !over || active.id === over.id) return

      const reorderPosition = calculateReorderPosition(event, areAllGroupsCollapsed, boxRef)
      if (!reorderPosition) return
      const { isPlacedBefore } = reorderPosition

      const activeIndex = groupNames.indexOf(active.id as string)
      const overIndex = groupNames.indexOf(over.id as string)

      if ((overIndex === activeIndex - 1 && !isPlacedBefore) || (overIndex === activeIndex + 1 && isPlacedBefore)) return // no-op cases

      const targetIndexBase = (isPlacedBefore ? overIndex : overIndex + 1) - (overIndex > activeIndex ? 1 : 0)
      const reorderedGroupNames = arrayMove(groupNames, activeIndex, targetIndexBase)

      setGroupNames(reorderedGroupNames) // optimistic update

      reorderGroup({
        variables: {
          group_name: active.id as string,
          move_after_group_name: targetIndexBase ? reorderedGroupNames[targetIndexBase - 1] : null
        }
      }) // backend update
    },
    [areAllGroupsCollapsed, groupNames, reorderGroup]
  )

  const handleDragMove = useCallback(
    (event: DragMoveEvent) => {
      const reorderPosition = calculateReorderPosition(event, areAllGroupsCollapsed, boxRef)

      if (reorderPosition) {
        setReorderLine(reorderPosition.reorderLine)
      }
    },
    [areAllGroupsCollapsed]
  )

  const context = useMemo(
    () => ({ areMultipleGroups, firstRowRefCallback, prepareRow, reorderLine, rows, top }),
    [areMultipleGroups, firstRowRefCallback, prepareRow, reorderLine, rows, top]
  )

  return (
    <Box ref={boxRef} sx={{ height: `calc(100vh - ${HEADER_HEIGHT + CONFIG_HEADER_HEIGHT}px)`, overflow: 'auto', position: 'relative', width: '100vw' }}>
      <ChecklistTableContext.Provider value={context}>
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragCancel={handleDragCancel}
          onDragEnd={areAllGroupsCollapsed ? handleGroupDragEnd : handleFieldDragEnd}
          onDragMove={handleDragMove}
          onDragStart={handleDragStart}
        >
          <Table {...getTableProps()} stickyHeader sx={{ mr: fieldOverlay.isOpen && !fieldOverlay.isClosing ? 105 : 0, width: '100vw' }}>
            <TableHead>
              {headerGroups.map(headerGroup => (
                <HeaderRow headerGroup={headerGroup} key={headerGroup.getHeaderGroupProps().key} />
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {groupNames.map(groupName => (
                <GroupAndFieldsRows groupName={groupName} key={groupName} />
              ))}
            </TableBody>
          </Table>

          <DragOverlay dropAnimation={null} style={{ zIndex: Z_INDEX_DRAG }}>
            <Box
              sx={{
                bgcolor: common.white,
                border: `1px solid ${grey[300]}`,
                borderRadius: 1,
                cursor: 'grabbing',
                px: 1,
                py: 0.5,
                position: 'absolute',
                right: 9,
                top: 9
              }}
            >
              {dragOverlay}
            </Box>
          </DragOverlay>

          {reorderLine !== null && (
            <Box
              sx={{ borderTop: `1px solid ${common.black}`, left: 0, pointerEvents: 'none', position: 'absolute', right: 0, top: reorderLine, zIndex: 100 }}
            />
          )}
        </DndContext>

        {fieldOverlay.isOpen && <FieldOverlay />}
      </ChecklistTableContext.Provider>
    </Box>
  )
}

const FieldRow: FC<_FieldRowProps> = ({ fieldId, group, row }) => {
  const { areMultipleFieldsVisible, isBusy, isReorderingEnabled } = useChecklistConfigContext()
  const { attributes, isDragging, listeners, setNodeRef: setDragRef } = useDraggable({ id: fieldId, disabled: !isReorderingEnabled })
  const { setNodeRef: setDropRef } = useDroppable({ data: { group, type: 'field' }, id: fieldId })

  const boxSx = useMemo(
    () => ({
      alignItems: 'center',
      display: 'flex',
      gap: 1,
      justifyContent: 'space-between',
      width: '100%',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: -1,
        left: 0,
        right: 0,
        borderTop: `1px solid ${grey[300]}`
      }
    }),
    []
  )

  const iconButtonSx = useMemo(
    () => ({ cursor: isDragging ? 'grabbing' : 'grab', mr: -1, my: -1, ':hover': { color: common.black }, ...(isDragging && { color: common.black }) }),
    [isDragging]
  )

  const setRef = useCallback(
    (node: HTMLTableRowElement) => {
      setDragRef(node)
      setDropRef(node)
    },
    [setDragRef, setDropRef]
  )

  return (
    <TableRow ref={setRef} {...row.getRowProps()}>
      {row.cells.map((cell, index) => {
        const { key, ...props } = cell.getCellProps()

        const showDragHandle = index === size(row.cells) - 1 && areMultipleFieldsVisible && isReorderingEnabled

        return (
          <TableCell
            {...props}
            key={key}
            sx={{ ...(!index ? { ...FIRST_CELL_SX, maxWidth: 300, pl: 1.5, pr: 1 } : CELL_SX), '&:last-of-type': { borderRight: 'none' } }}
          >
            <Box sx={{ ...boxSx, ...(showDragHandle && { flexDirection: 'row-reverse' }) }}>
              {showDragHandle && (
                <IconButton disabled={isBusy} onClick={event => event.stopPropagation()} size="small" sx={iconButtonSx} {...attributes} {...listeners}>
                  <DragIndicatorIcon />
                </IconButton>
              )}

              {cell.render('Cell')}
            </Box>
          </TableCell>
        )
      })}
    </TableRow>
  )
}

const GroupAndFieldsRows: FC<_GroupAndFieldsRowsProps> = ({ groupName }) => {
  const { filterGroup, filterSearchTerm, isGroupCollapsedMap } = useChecklistConfigContext()
  const { prepareRow, rows, top } = useChecklistTableContext()

  const groupFields = useMemo(() => rows.filter(row => filterGroup(row, groupName)).filter(filterSearchTerm), [rows, groupName, filterGroup, filterSearchTerm])

  const boxSx = useMemo(
    () => ({
      alignItems: 'center',
      bgcolor: common.white,
      borderBottom: `1px solid ${grey[300]}`,
      borderTop: `1px solid ${grey[300]}`,
      bottom: 0,
      display: 'flex',
      left: 0,
      pl: 6.25,
      position: 'absolute',
      top: -1,
      width: '100vw'
    }),
    []
  )

  const tableCellSx = useMemo(() => ({ ...FIRST_CELL_SX, borderBottom: 0, borderTop: 0, pl: 1, top, zIndex: 3 }), [top])

  return (
    <>
      <GroupRow groupName={groupName} />

      {!isGroupCollapsedMap[groupName] &&
        (isEmpty(groupFields) ? (
          <TableRow>
            <TableCell sx={tableCellSx}>
              <Box sx={boxSx}>
                <Typography sx={{ fontSize: 14, fontStyle: 'italic' }}>No fields found.</Typography>
              </Box>
            </TableCell>
          </TableRow>
        ) : (
          <>
            {groupFields.map(row => {
              prepareRow(row)

              return <FieldRow fieldId={row.original.id} group={row.original.group} key={row.getRowProps().key} row={row} />
            })}
          </>
        ))}
    </>
  )
}

const GroupRow: FC<_GroupRowProps> = ({ groupName }) => {
  const { areAllGroupsCollapsed, isBusy, isGroupCollapsedMap, isReorderingEnabled, setIsGroupCollapsedMap } = useChecklistConfigContext()
  const { areMultipleGroups, top } = useChecklistTableContext()
  const { attributes, isDragging, listeners, setNodeRef: setDragRef } = useDraggable({ id: groupName })
  const { setNodeRef: setDropRef } = useDroppable({ data: { type: 'group' }, id: groupName })

  const boxSx = useMemo(
    () => ({
      alignItems: 'center',
      bgcolor: common.white,
      borderBottom: `1px solid ${grey[300]}`,
      borderTop: `1px solid ${grey[300]}`,
      bottom: 0,
      display: 'flex',
      justifyContent: 'space-between',
      left: 0,
      pl: 1,
      position: 'absolute',
      top: -1,
      width: '100vw',
      ...(isDragging && { cursor: 'grabbing' })
    }),
    [isDragging]
  )

  const dragHandleIconButtonSx = useMemo(
    () => ({ cursor: isDragging ? 'grabbing' : 'grab', mr: 1, ':hover': { color: common.black }, ...(isDragging && { color: common.black }) }),
    [isDragging]
  )

  const isGroupCollapsed = useMemo(() => isGroupCollapsedMap[groupName], [groupName, isGroupCollapsedMap])

  const expandMoreIconButtonSx = useMemo(
    () => ({
      ':hover': { color: common.black },
      '& .MuiSvgIcon-root': {
        transform: `rotate(${isGroupCollapsed ? '-90' : '0'}deg)`,
        transition: 'transform 0.1s'
      }
    }),
    [isGroupCollapsed]
  )

  const tableCellSx = useMemo(() => ({ ...FIRST_CELL_SX, borderBottom: 0, borderTop: 0, pl: 1, top, zIndex: 3 }), [top])

  const setRef = useCallback(
    (node: HTMLTableRowElement) => {
      setDragRef(node)
      setDropRef(node)
    },
    [setDragRef, setDropRef]
  )

  const toggleGroupCollapse = useCallback(
    (groupName: string) => setIsGroupCollapsedMap(previous => ({ ...previous, [groupName]: !previous[groupName] })),
    [setIsGroupCollapsedMap]
  )

  return (
    <TableRow ref={setRef}>
      <TableCell sx={tableCellSx}>
        <Box sx={boxSx}>
          <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
            <IconButton disabled={isBusy} onClick={() => toggleGroupCollapse(groupName)} size="small" sx={expandMoreIconButtonSx}>
              <ExpandMoreIcon />
            </IconButton>
            <Typography sx={{ fontSize: 16, fontWeight: 600, whiteSpace: 'nowrap' }}>{groupName}</Typography>
          </Box>

          {areAllGroupsCollapsed && areMultipleGroups && isReorderingEnabled && (
            <IconButton disabled={isBusy} onClick={event => event.stopPropagation()} size="small" sx={dragHandleIconButtonSx} {...attributes} {...listeners}>
              <DragIndicatorIcon />
            </IconButton>
          )}
        </Box>
      </TableCell>
    </TableRow>
  )
}

const HeaderRow: FC<_HeaderRowProps> = ({ headerGroup }) => {
  const { areAllGroupsCollapsed, isBusy, setIsGroupCollapsedMap } = useChecklistConfigContext()
  const { firstRowRefCallback } = useChecklistTableContext()

  const iconButtonSx = useMemo(
    () => ({
      '& .MuiSvgIcon-root': {
        transform: `rotate(${areAllGroupsCollapsed ? '-90' : '0'}deg)`,
        transition: 'transform 0.1s'
      }
    }),
    [areAllGroupsCollapsed]
  )

  const tableCellSx = useMemo(() => ({ bgcolor: grey[100], position: 'sticky', top: 0, whiteSpace: 'nowrap', '&:last-of-type': { borderRight: 'none' } }), [])

  const toggleGroupsCollapse = useCallback(() => {
    setIsGroupCollapsedMap(previous => {
      const isCollapsing = Object.values(previous).some(value => !value)

      return Object.fromEntries(Object.keys(previous).map(key => [key, isCollapsing]))
    })
  }, [setIsGroupCollapsedMap])

  return (
    <TableRow {...headerGroup.getHeaderGroupProps()} ref={firstRowRefCallback}>
      {headerGroup.headers.map((column, index) => {
        const { key, ...props } = column.getHeaderProps()

        return (
          <TableCell
            {...props}
            key={key}
            sx={{
              ...(!index ? { ...FIRST_CELL_SX, pl: 1, zIndex: 3 } : CELL_SX),
              ...tableCellSx,
              ...(SAMPLES_COLUMNS.includes(column.Header as string) && { bgcolor: grey[200], width: 10 }),
              ...(column.Header === SAMPLES_COLUMNS[0] && { fontWeight: 600 })
            }}
          >
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
              {!index && (
                <IconButton disabled={isBusy} onClick={toggleGroupsCollapse} size="small" sx={iconButtonSx}>
                  <ExpandMoreIcon />
                </IconButton>
              )}

              {column.render('Header')}
            </Box>
          </TableCell>
        )
      })}
    </TableRow>
  )
}
