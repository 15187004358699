import '@mescius/spread-sheets-charts'
import '@mescius/spread-sheets-io'
import '@mescius/spread-sheets-shapes'
import '@mescius/spread-sheets/styles/gc.spread.sheets.excel2013white.css'
import 'react-resizable/css/styles.css'
import { Box, TextField } from '@mui/material'
import { Cursors } from '../../../Resizable/Resizable'
import { FORMULA_PREFIX, MAX_TOOLBAR_HEIGHT, MIN_TOOLBAR_HEIGHT, RESIZABLE_HANDLE_HEIGHT, STATUS_BAR_HEIGHT, WORKBOOK_OPTION_OVERRIDES } from './constants'
import { Resizable, ResizeCallbackData } from 'react-resizable'
import { SPREADJS_LICENSE_KEY } from '../../../SpreadsheetViewer/spreadJsLicenseKey'
import { SpreadSheets, Worksheet } from '@mescius/spread-sheets-react'
import { SpreadsheetErrorBoundary } from '../../../SpreadsheetViewer/SpreadsheetErrorBoundary'
import { Z_INDEX_1 } from '../../../../utils/styleUtils'
import { captureError } from '../../../../utils/sentry'
import { getSpreadsheetData } from './utils/dataManagement'
import { grey } from '@mui/material/colors'
import { initializeSheet, setWorkbookOptions, setWorksheetOptions } from './utils/sheetConfig'
import { setCellSelectionHandler, setChangeEventHandlers } from './utils/eventHandlers'
import { setCustomContextMenu } from './utils/contextMenu'
import { useAppContext } from '../../../../app'
import GC from '@mescius/spread-sheets'
import Loader from '../../../Loader/Loader'
import React, { FC, MutableRefObject, SyntheticEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

GC.Spread.Sheets.LicenseKey = SPREADJS_LICENSE_KEY

// types

export type _Column = { name: string; type?: string }

export type _Row = { [k: string]: string }

type _SpreadsheetEditorProps = {
  columns: _Column[]
  id: string
  isReadOnly?: boolean
  onChange?: () => void
  rows?: _Row[]
}

export type _SpreadsheetEditorRef = {
  getSpreadsheetData: () => { columns: _Column[]; emptyColumnHeaderIndices: number[]; isUnchanged: boolean; rows: _Row[] } | null
  getWorkbook: () => GC.Spread.Sheets.Workbook | null
  initializeSheet: (columns: _Column[], rows?: _Row[]) => void
}

type _ToolbarHandleProps = { cursor: Cursors; handleAxis?: unknown }

type _ToolbarProps = {
  cellInfo: { column?: number; formula?: string; row?: number; value?: string } | null
  isReadOnly?: boolean
  onValueChange?: () => void
  setToolbarHeight: (height: number) => void
  toolbarHeight: number
  workbookRef: MutableRefObject<GC.Spread.Sheets.Workbook | null>
}

// components

export const SpreadsheetEditor = forwardRef<_SpreadsheetEditorRef, _SpreadsheetEditorProps>(({ columns, id, isReadOnly = false, onChange, rows }, ref) => {
  const { setErrorMessage } = useAppContext()

  const [cellInfo, setCellInfo] = useState<_ToolbarProps['cellInfo']>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [toolbarHeight, setToolbarHeight] = useState(MIN_TOOLBAR_HEIGHT)

  const hasInitialized = useRef(false)
  const previousColumnCount = useRef(0)
  const workbookRef = useRef<GC.Spread.Sheets.Workbook | null>(null)

  const handleWorkbookInit = (workbookInstance: GC.Spread.Sheets.Workbook) => {
    workbookRef.current = workbookInstance

    const statusBarId = `status-bar-${id}`
    const statusBar = new GC.Spread.Sheets.StatusBar.StatusBar(window.document.getElementById(statusBarId)!)
    statusBar.bind(workbookInstance)

    loadDataIntoSpreadsheet()
  }

  const loadDataIntoSpreadsheet = useCallback(() => {
    if (!workbookRef.current || rows === undefined) return

    try {
      const sheet = workbookRef.current.getActiveSheet()

      initializeSheet(sheet, columns, rows)

      if (!hasInitialized.current) {
        setWorkbookOptions(workbookRef.current)

        setWorksheetOptions(sheet, isReadOnly)

        setCellSelectionHandler(sheet, setCellInfo)

        setCustomContextMenu(workbookRef.current, onChange)

        if (!isReadOnly) setChangeEventHandlers(sheet, onChange, previousColumnCount, columns)

        hasInitialized.current = true
      }

      setIsLoading(false)
    } catch (error) {
      captureError(error)

      setErrorMessage('Error loading data into spreadsheet')
    }
  }, [columns, isReadOnly, onChange, rows, setErrorMessage])

  // Expose methods to parent component.
  useImperativeHandle(ref, () => ({
    getSpreadsheetData: () => getSpreadsheetData(workbookRef.current, columns, rows || [], setErrorMessage),
    getWorkbook: () => workbookRef.current,
    initializeSheet: (columns: _Column[], rows?: _Row[]) => {
      if (!workbookRef.current) return

      const sheet = workbookRef.current.getActiveSheet()

      initializeSheet(sheet, columns, rows)

      setCellInfo(null)
    }
  }))

  useEffect(() => {
    if (workbookRef.current) loadDataIntoSpreadsheet()
  }, [loadDataIntoSpreadsheet])

  if (rows === undefined) return null

  return (
    <SpreadsheetErrorBoundary>
      <Box
        sx={{
          borderTop: `1px solid ${grey[300]}`,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'flex-end',
          position: 'relative',
          textAlign: 'initial'
        }}
      >
        <Toolbar
          cellInfo={cellInfo}
          isReadOnly={isReadOnly}
          onValueChange={onChange}
          setToolbarHeight={setToolbarHeight}
          toolbarHeight={toolbarHeight}
          workbookRef={workbookRef}
        />

        <Box sx={{ height: `calc(100% - ${toolbarHeight + RESIZABLE_HANDLE_HEIGHT + STATUS_BAR_HEIGHT}px)` }}>
          {isLoading && (
            <Box sx={{ background: 'white', display: 'flex', height: '-webkit-fill-available', position: 'absolute', zIndex: Z_INDEX_1 }}>
              <Loader />
            </Box>
          )}

          <SpreadSheets {...WORKBOOK_OPTION_OVERRIDES} workbookInitialized={handleWorkbookInit}>
            <Worksheet />
          </SpreadSheets>
        </Box>

        <Box
          id={`status-bar-${id}`}
          sx={{ height: STATUS_BAR_HEIGHT, '& .gc-statusbar': { background: grey[700] }, '& [title="In Ready mode"]': { opacity: 0 } }}
        />
      </Box>
    </SpreadsheetErrorBoundary>
  )
})

export const Toolbar: FC<_ToolbarProps> = ({ cellInfo, isReadOnly = false, onValueChange, setToolbarHeight, toolbarHeight, workbookRef }) => {
  const [isResizing, setIsResizing] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const cursor = toolbarHeight >= MAX_TOOLBAR_HEIGHT ? Cursors.MINIMUM : toolbarHeight <= MIN_TOOLBAR_HEIGHT ? Cursors.MAXIMUM : Cursors.BETWEEN

  const textFieldStyles = {
    height: toolbarHeight,
    p: 1,
    textarea: { boxSizing: 'border-box', height: `${toolbarHeight - 16}px !important`, overflow: 'scroll !important', p: 1 },
    '& .MuiInputBase-root': { alignItems: 'flex-start', overflow: 'hidden', p: 0 },
    '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: grey[300] } }
  }

  const handleBlur = () => {
    // Update sheet cell value when the field is blurred.
    if (workbookRef.current && cellInfo?.row !== undefined && cellInfo?.column !== undefined) {
      const sheet = workbookRef.current.getActiveSheet()
      const isFormula = inputValue.startsWith(FORMULA_PREFIX)

      if (isFormula) {
        sheet.setFormula(cellInfo.row, cellInfo.column, inputValue.replace(FORMULA_PREFIX, ''))
      } else {
        sheet.setValue(cellInfo.row, cellInfo.column, inputValue)
      }
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value

    setInputValue(newValue)

    // Update sheet cell value immediately without formatting.
    if (workbookRef.current && cellInfo?.row !== undefined && cellInfo?.column !== undefined) {
      const sheet = workbookRef.current.getActiveSheet()
      const isFormula = newValue.startsWith(FORMULA_PREFIX)

      if (isFormula) {
        sheet.setFormula(cellInfo.row, cellInfo.column, newValue.replace(FORMULA_PREFIX, ''))
      } else {
        sheet.setValue(cellInfo.row, cellInfo.column, newValue)
      }

      onValueChange?.()
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') event.currentTarget.blur()
  }

  const handleResize = (_: SyntheticEvent, { size }: ResizeCallbackData) => {
    setToolbarHeight(size.height)

    workbookRef.current?.refresh()
  }

  useEffect(() => {
    setInputValue(cellInfo?.formula || cellInfo?.value || '')
  }, [cellInfo])

  return (
    <Resizable
      axis="y"
      handle={<ToolbarHandle cursor={cursor} />}
      height={toolbarHeight}
      maxConstraints={[Infinity, MAX_TOOLBAR_HEIGHT]}
      minConstraints={[Infinity, MIN_TOOLBAR_HEIGHT]}
      onResize={handleResize}
      onResizeStart={() => setIsResizing(true)}
      onResizeStop={() => setIsResizing(false)}
      width={Infinity}
    >
      <Box
        sx={{
          background: 'white',
          height: toolbarHeight,
          left: 0,
          pointerEvents: isResizing ? 'none' : 'auto',
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: Z_INDEX_1
        }}
      >
        <TextField
          disabled={isReadOnly}
          fullWidth
          multiline
          onBlur={handleBlur}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          sx={textFieldStyles}
          value={inputValue}
          variant="outlined"
        />
      </Box>
    </Resizable>
  )
}

// See: https://github.com/react-grid-layout/react-resizable/issues/175#issuecomment-1101142158
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ToolbarHandle = forwardRef<HTMLDivElement, _ToolbarHandleProps>(({ cursor, handleAxis, ...rest }, ref) => (
  <Box
    ref={ref}
    sx={{ backgroundColor: grey[300], bottom: 0, cursor, height: RESIZABLE_HANDLE_HEIGHT, left: 0, position: 'sticky', width: '100%' }}
    {...rest}
  />
))
