import { AdvancedDashboardData } from '../../graphql/codegen/schemas'
import { CellView } from '../dashboard/CellView'
import { Column, DocumentQueryAction } from '../../reducers/dashboardQueryReducer'
import { DealsDashboardQuery } from '../../graphql/codegen/operations'
import { ExtractionStatusLabels, ExtractionStatuses } from '../../utils/extractionUtils'
import { Features, Permissions, useUserAccess } from '../../hooks/useUserAccess'
import { FiAlertTriangle } from 'react-icons/fi'
import { LazyQueryResultTuple } from '@apollo/client'
import { Link, useHistory } from 'react-router-dom'
import { MoreMenu, _MenuItem } from '../MoreMenu'
import { RerunDealExtractionButton } from '../RerunExtraction'
import { Row } from 'react-table'
import { Table } from '../Table'
import { captureError } from '../../utils/sentry'
import { formatDataPointId } from '../../utils/fieldUtils'
import { generateDashboardColumns, generateSortDirection, generateSortFunction } from '../../utils/dashboardColumnUtils'
import { getErrorsColumn } from '../dashboard/ErrorsCell'
import { useAreFailureNotificationsEnabled } from '../../hooks/useAreFailureNotificationsEnabled'
import {
  useBulkEditDealAssigneeMutation,
  useDashboardConfigQuery,
  useDealCountLazyQuery,
  useDealsDashboardExtractionStatusesQuery
} from '../../graphql/codegen/hooks'
import { useIsExtractionRerunEnabled } from '../../hooks/useIsExtractionRerunEnabled'
import { useIsZuoraTenant } from '../../hooks/useIsZuoraTenant'
import { useModalContext } from '../../app'
import { useTranslation } from 'react-i18next'
import BulkEditAssigneeButton from '../../containers/BulkEditAssigneeButton'
import BulkEditStatusButton from '../../containers/BulkEditStatusButton'
import ComponentLoadingOverlay from '../ComponentLoadingOverlay'
import DeleteDeal from '../ModalOptions/DeleteDeal'
import Modal from '../Modal'
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import RenameFile from '../RenameFile'
import ViewDealsDocuments from '../ModalOptions/ViewDealsDocuments'
import css from './style.module.scss'
import useDashboardQString from '../../hooks/useDashboardQString'
import useTablePagination from '../../hooks/useTablePagination'

// types

type _DealListProps = {
  dealsDashboardLazyQueryResult: LazyQueryResultTuple<DealsDashboardQuery, any>
  dispatch: Dispatch<DocumentQueryAction>
  selectedColumns: Column[]
  setClearAll: Dispatch<SetStateAction<boolean>>
  setFilteredCount: Dispatch<SetStateAction<number>>
  setTotalCount: Dispatch<SetStateAction<number>>
}

export type _FailureNotification = { error_code: string; error_message: string }

// functions

const getAccessor = (value: string) => {
  switch (value) {
    case '_id':
      return 'document_id'
    case 'is_legacy_str':
      return 'is_legacy'
    default:
      return value
  }
}

const hasExtractionStatusChanged = (previousData: AdvancedDashboardData[] | undefined, newData: AdvancedDashboardData[] | undefined) => {
  if (!previousData || !newData) return false

  const previousStatuses = previousData.map(item => item?.extraction_status?.value)
  const newStatuses = newData.map(item => item?.extraction_status?.value)

  return JSON.stringify(previousStatuses) !== JSON.stringify(newStatuses)
}

// components

export const DealList: FC<_DealListProps> = ({ dealsDashboardLazyQueryResult, dispatch, selectedColumns, setClearAll, setFilteredCount, setTotalCount }) => {
  const [dealsDashboardQuery, { data: dataDeals, loading: loadingDeals }] = dealsDashboardLazyQueryResult
  const { data: dashboardConfigurationData } = useDashboardConfigQuery()
  const history = useHistory()
  const [rowCount, setRowCount] = useState(10)
  const { offset, pageLength, ...paginationControls } = useTablePagination({ total: rowCount })
  const { t } = useTranslation()
  const { gqlFilters, queryBuilderQuery, sortConfig } = useDashboardQString()

  const canChangeDealCounterparty = useUserAccess({ feature: Features.DEAL, permission: Permissions.UPDATE_COUNTERPARTY })
  const canDeleteDeal = useUserAccess({ feature: Features.DEAL, permission: Permissions.DELETE })
  const hasDemoAuditorButtonPermissions = useUserAccess({ feature: Features.DEMO_AUDITOR_BUTTON, permission: Permissions.READ })
  const isExtractionRerunEnabled = useIsExtractionRerunEnabled()
  const areFailureNotificationsEnabled = useAreFailureNotificationsEnabled()

  const [dealCountLazyQuery, { data: dataCount, loading: loadingCount, refetch: refetchCount }] = useDealCountLazyQuery({
    fetchPolicy: 'network-only',
    variables: { ...gqlFilters }
  })

  const { refetch: getExtractionStatuses } = useDealsDashboardExtractionStatusesQuery({
    fetchPolicy: 'network-only',
    skip: !isExtractionRerunEnabled,
    variables: { ...gqlFilters, size: pageLength, offset }
  })

  const isLoading = loadingDeals || loadingCount

  useEffect(() => {
    refetchCount()
  }, [dataDeals, refetchCount])

  useEffect(() => {
    dealsDashboardQuery({ variables: { ...gqlFilters, size: pageLength, offset } })
  }, [dealsDashboardQuery, gqlFilters, offset, pageLength])

  useEffect(() => {
    if (!dataDeals || !isExtractionRerunEnabled) return

    let previousData = dataDeals?.fetch_advanced_deals_dashboard?.dashboard_data as AdvancedDashboardData[] | undefined

    const intervalId = setInterval(async () => {
      if (dataDeals?.fetch_advanced_deals_dashboard?.dashboard_data?.some(item => item?.extraction_status?.value === ExtractionStatuses.PROCESSING)) {
        const { data: newExtractionData } = await getExtractionStatuses()
        const newData = newExtractionData?.fetch_advanced_deals_dashboard?.dashboard_data as AdvancedDashboardData[] | undefined

        if (hasExtractionStatusChanged(previousData, newData)) {
          dealsDashboardQuery({ variables: { ...gqlFilters, size: pageLength, offset } })

          previousData = newData
        }
      }
    }, 30000)

    return () => clearInterval(intervalId)
  }, [dataDeals, getExtractionStatuses, gqlFilters, isExtractionRerunEnabled, offset, pageLength, dealsDashboardQuery])

  useEffect(() => {
    // ONLY recount if the search changed!
    dealCountLazyQuery({ variables: gqlFilters })
  }, [gqlFilters, dealCountLazyQuery])

  useEffect(() => {
    // ONLY recount if the search changed!
    if (dataCount && dataCount.fetch_advanced_deals_dashboard_count) {
      setFilteredCount(dataCount.fetch_advanced_deals_dashboard_count.total_number_of_filtered_documents!)
      setTotalCount(dataCount.fetch_advanced_deals_dashboard_count.total_number_of_documents!)
    } else {
      setFilteredCount(0)
      setTotalCount(0)
    }
  }, [dataCount, setFilteredCount, setTotalCount])

  const totalFiltered = dataCount?.fetch_advanced_deals_dashboard_count?.total_number_of_filtered_documents
  useEffect(() => {
    setRowCount(totalFiltered || 0)
  }, [totalFiltered])

  const tableData = useMemo(() => {
    if (!dataDeals) return []

    const dashboardData = dataDeals.fetch_advanced_deals_dashboard?.dashboard_data
    const normalized = dashboardData?.map((row: any) => {
      const data_points = row.data_points.reduce((acc: any, curr: any) => {
        const id = formatDataPointId(curr)
        acc[id] = curr
        return acc
      }, {})
      return { ...row, ...data_points, created_at: row.created_at, state: row.status }
    })
    return normalized // this is an object with keys that map to the 'accessor' passed to columns below
  }, [dataDeals])

  const renderCell = useCallback((column: Column, { row, value }: { row: any; value: any }) => {
    if (!value) return null

    let displayValue = value?.value || null
    if (column.data_type === 'MULTI_SELECT_DROP_DOWN' && value.value) {
      // Response is a stringified array with single quotes, so this parses it into a list
      try {
        const withQuotesReplaced = value.value.split("'").join('"')
        displayValue = JSON.parse(withQuotesReplaced).join(', ')
      } catch (error) {
        captureError(error)
      }
    } else if (column.value === 'assignee') {
      displayValue = value.value?.user_name
    } else if (column.value === 'all_assignees') {
      displayValue = value.reduce((acc: string, current: any, idx: number) => {
        if (idx !== 0) {
          acc += ', '
        }

        acc += current?.value?.user_name || ''

        return acc
      }, '')
    }

    if (value.is_collision_resolved === false) {
      // must check for false value - right now if true it can either be true or null
      return (
        <div>
          <Link className={css.reviewLink} to={`/deals/${row?.original?.document?.id}?unresolved=${value.data_point_id}`}>
            <div>
              <FiAlertTriangle />
            </div>

            <div>{`${displayValue} conflicting values`}</div>
          </Link>
        </div>
      )
    }

    if (value.hyperlink_url) {
      return (
        <CellView header={column.label} textValue={displayValue}>
          <a href={value.hyperlink_url} rel="noreferrer" target="_blank">
            {displayValue}
          </a>
        </CellView>
      )
    }

    return <CellView header={column.label} textValue={displayValue} />
  }, [])

  // Take selectedColumns from reducer, format for react table
  // The table response does not return which values have been sorted by, so we add it into the column data here.
  const dynamicColumns = useMemo(
    () =>
      (selectedColumns || []).map(column => {
        if (!column) return null

        let sortFunction = null
        let sortDirection: -1 | 0 | 1 = 0

        if (column.data_point_field_id) {
          sortDirection = generateSortDirection(sortConfig, 'sort_data_points', column.data_point_field_id)
          sortFunction = generateSortFunction(history, queryBuilderQuery, sortConfig, 'sort_data_points', selectedColumns, column.data_point_field_id)
        } else if (
          column.value === 'attachments_count' ||
          column.value === 'created_at' ||
          column.value === 'documents_count' ||
          column.value === 'unresolved_comments_count'
        ) {
          sortDirection = generateSortDirection(sortConfig, `sort_${column.value}`)
          sortFunction = generateSortFunction(history, queryBuilderQuery, sortConfig, `sort_${column.value}`, selectedColumns)
        } else if (column.value === 'status' || column.value === 'state') {
          sortDirection = generateSortDirection(sortConfig, 'sort_state')
          sortFunction = generateSortFunction(history, queryBuilderQuery, sortConfig, 'sort_state', selectedColumns)
        } else if (column.value === 'assignee') {
          sortDirection = generateSortDirection(sortConfig, 'sort_assignee_user_name')
          sortFunction = generateSortFunction(history, queryBuilderQuery, sortConfig, 'sort_assignee_user_name', selectedColumns)
        }

        return {
          ...column,
          Header: column.label,
          accessor: getAccessor(column.value),
          sortDirection,
          sortFunction,
          draggable: true,
          Cell: (rowAndValue: { row: any; value: any }) => renderCell(column, rowAndValue)
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedColumns, history, sortConfig]
  )

  const createSelectionActionsRenderer = useCallback(
    () => (selectedFlatRows: Row<{}>[], rows?: Row<{}>[]) =>
      selectedFlatRows?.length > 0 ? (
        <>
          <BulkEditStatusButton resourceName="deal" selectedFlatRows={selectedFlatRows} />

          <BulkEditAssigneeButton
            bulkEditAssigneeMutation={useBulkEditDealAssigneeMutation}
            entityName="Assignee"
            resourceName="deal"
            selectedFlatRows={selectedFlatRows}
          />

          {hasDemoAuditorButtonPermissions && (
            <BulkEditAssigneeButton
              bulkEditAssigneeMutation={useBulkEditDealAssigneeMutation}
              entityName="Auditor"
              resourceName="deal"
              selectedFlatRows={selectedFlatRows}
            />
          )}

          {isExtractionRerunEnabled && rows && <RerunDealExtractionButton rows={rows} selectedFlatRows={selectedFlatRows} />}
        </>
      ) : null,
    [hasDemoAuditorButtonPermissions, isExtractionRerunEnabled]
  )

  const { openModal } = useModalContext()
  const [modalLoading, setModalLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [modalOption, setModalOption] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState<null | { counterpartyId?: string; dealAlias?: string; dealId: string; dealName: string }>(null)
  const isZuoraTenant = useIsZuoraTenant()

  const openLocalModal = (menuOption: string, dealId: string, dealName: string, dealAlias?: string, counterpartyId?: string) => {
    setIsOpen(true)
    setModalOption(menuOption)
    setModalTitle(menuOption)
    setModalContent({ dealId, dealName, dealAlias, counterpartyId })
  }

  const closeModal = () => {
    if (!modalLoading) {
      setIsOpen(false)
      setModalOption('')
      setModalTitle('')
      setModalContent(null)
    }
  }

  const getMenuItemList = useCallback(
    ({ counterpartyId, counterpartyName, dealAlias, dealId, dealIsFinalized, dealName }): _MenuItem[] => [
      { label: `View ${t('Deal')}’s Documents`, onClick: () => openLocalModal(`View ${t('Deal')}’s Documents`, dealId, dealName, dealAlias, counterpartyId) },
      ...(dealIsFinalized
        ? []
        : [{ label: `Rename ${t('Deal')}`, onClick: () => openLocalModal(`Rename ${t('Deal')}`, dealId, dealName, dealAlias, counterpartyId) }]),
      ...(!dealIsFinalized && canChangeDealCounterparty
        ? [
            {
              label: `Change ${t('Deal')}’s Customer`,
              onClick: () => {
                openModal('ChangeCounterparty', `Change ${t('Deal')}’s Customer`, {
                  itemType: 'Deal',
                  itemName: dealAlias || dealName,
                  dealId,
                  counterpartyId,
                  counterpartyName
                })
              }
            }
          ]
        : []),
      ...(!dealIsFinalized && canDeleteDeal
        ? [{ label: `Delete ${t('Deal')}`, onClick: () => openLocalModal(`Delete ${t('Deal')}`, dealId, dealName, dealAlias, counterpartyId) }]
        : [])
    ],
    [canChangeDealCounterparty, canDeleteDeal, openModal, t]
  )

  const staticColumns = useMemo(
    () => [
      ...generateDashboardColumns(history, queryBuilderQuery, sortConfig, selectedColumns, [
        {
          headerName: t('Deal'),
          accessor: 'document',
          sortStringName: 'document_name',
          Cell: ({ row, value }: any) => {
            if (!value) return null
            const dealId = value?.id
            const dealName: string = value?.value_original || value?.value || ''
            const dealAlias: string = value?.value_alias || ''
            const counterpartyId = row?.values?.counter_party?.value && JSON.parse(row?.values?.counter_party?.value)?.id
            const counterpartyName = row?.values?.counter_party?.value && JSON.parse(row?.values?.counter_party?.value)?.name
            const dealIsFinalized = row?.values?.state?.final
            const dealIsProcessing = row?.original?.status?.value === 'Processing'

            if (row.original?.processed !== false) {
              const title = dealAlias && `Original Name: ${dealName}`

              return (
                <CellView
                  header={t('Deal')}
                  menu={
                    <MoreMenu
                      menuItemList={getMenuItemList({ dealId, dealName: value?.value, dealAlias, counterpartyId, counterpartyName, dealIsFinalized })}
                    />
                  }
                  textValue={dealAlias || dealName}
                  title={title}
                >
                  {!dealIsProcessing ? <Link to={`/deals/${value?.id}`}>{dealAlias || dealName}</Link> : dealAlias || dealName}
                </CellView>
              )
            } else {
              return <CellView header={t('Deal')} textValue={dealAlias || dealName} />
            }
          }
        },
        ...getErrorsColumn(areFailureNotificationsEnabled),
        ...(isExtractionRerunEnabled
          ? [
              {
                headerName: 'Extraction Status',
                accessor: 'extraction_status.value',
                disableSort: true,
                Cell: ({ value }: any) => (
                  <CellView header="Extraction Status" textValue={ExtractionStatusLabels[value as keyof typeof ExtractionStatuses] || ''} />
                )
              }
            ]
          : []),
        {
          headerName: 'Customer',
          accessor: 'counter_party',
          sortStringName: 'counter_party_name',
          Cell: ({ value }: any) => {
            const parsedCounterParty = value?.value && JSON.parse(value?.value)
            const textValue: string = (parsedCounterParty?.name !== 'UNASSIGNED' && parsedCounterParty?.name) || ''

            return <CellView header="Customer" textValue={textValue} />
          }
        },
        {
          headerName: 'Assignee',
          accessor: 'assignee',
          sortStringName: 'sort_assignee_user_name',
          Cell: ({ value }: any) => {
            const textValue: string = value?.value?.user_name || ''

            return <CellView header="Assignee" textValue={textValue} />
          }
        },
        ...(isZuoraTenant
          ? [
              {
                accessor: 'status',
                headerName: 'Status',
                sortStringName: 'status_name',
                Cell: ({ value }: any) => {
                  const textValue: string = value?.value || ''

                  return <CellView header="Status" textValue={textValue} />
                }
              }
            ]
          : [])
      ])
    ],
    [areFailureNotificationsEnabled, getMenuItemList, history, isExtractionRerunEnabled, isZuoraTenant, queryBuilderQuery, selectedColumns, sortConfig, t]
  )

  const filteredColumns = useMemo(() => {
    const isColumnIncluded = (internalName: string) =>
      dashboardConfigurationData?.dashboard_config?.deal_default_fields?.edges?.find(edge => edge?.node?.internal_name === internalName)?.node?.is_visible !==
        false &&
      (!isZuoraTenant || internalName !== 'state')

    return [...staticColumns, ...dynamicColumns].filter(column => isColumnIncluded(column?.accessor as string))
  }, [dashboardConfigurationData?.dashboard_config?.deal_default_fields?.edges, dynamicColumns, isZuoraTenant, staticColumns])

  if (!dashboardConfigurationData) {
    return null
  }

  return (
    <>
      <Table
        {...paginationControls}
        columns={filteredColumns}
        dispatch={dispatch}
        isLoading={isLoading || !dataCount}
        pageLength={pageLength}
        renderSelectionActions={createSelectionActionsRenderer()}
        rowCount={rowCount}
        setClearAll={setClearAll}
        tableData={tableData}
      />

      <Modal isOpen={isOpen} onRequestClose={closeModal} title={modalTitle}>
        {modalLoading && <ComponentLoadingOverlay loading={modalLoading} message={loadingMessage} />}
        <div className={css.modal}>
          {modalOption !== `Rename ${t('Deal')}` && modalOption !== `Delete ${t('Deal')}` && <h4>{modalContent?.dealAlias || modalContent?.dealName}</h4>}
          {modalOption === `View ${t('Deal')}’s Documents` && (
            <ViewDealsDocuments closeModal={closeModal} dealCounterpartyId={modalContent?.counterpartyId} dealId={modalContent?.dealId} />
          )}
          {modalOption === `Rename ${t('Deal')}` && (
            <RenameFile
              closeModal={closeModal}
              dealId={modalContent?.dealId}
              fileAlias={modalContent?.dealAlias}
              fileName={modalContent?.dealName}
              fileType={'Deal'}
              loading={modalLoading}
              setLoading={setModalLoading}
              setLoadingMessage={setLoadingMessage}
            />
          )}
          {modalOption === `Delete ${t('Deal')}` && (
            <DeleteDeal
              closeModal={closeModal}
              dealName={modalContent?.dealAlias || modalContent?.dealName}
              deal_id={modalContent?.dealId}
              setLoadingMessage={setLoadingMessage}
              setModalLoading={setModalLoading}
            />
          )}
        </div>
      </Modal>
    </>
  )
}
