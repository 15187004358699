import { EXCLUDED_PASTE_TYPES } from '../constants'
import { applyColumnHeaderStyles } from './sheetConfig'
import GC from '@mescius/spread-sheets'

// functions

const createCustomContextMenuActions = (workbook: GC.Spread.Sheets.Workbook, onChange?: () => void) => {
  const createAction = (name: string, text: string, iconClass: string, action: (sheet: GC.Spread.Sheets.Worksheet) => void) => ({
    name,
    text,
    iconClass,
    command: () => {
      const sheet = workbook.getActiveSheet()

      if (sheet) {
        action(sheet)
        onChange?.()
      }
    }
  })

  const appendColumnsAction = createAction('appendColumns', 'Append more columns', '', sheet => {
    sheet.setColumnCount(sheet.getColumnCount() + 26)

    applyColumnHeaderStyles(sheet, 0, sheet.getColumnCount())
  })

  const appendRowsAction = createAction('appendRows', 'Append more rows', '', sheet => sheet.setRowCount(sheet.getRowCount() + 100))

  return { appendColumnsAction, appendRowsAction }
}

export const setCustomContextMenu = (workbook: GC.Spread.Sheets.Workbook, onChange?: () => void) => {
  const defaultMenuItems = workbook.contextMenu.menuData.filter(item => {
    if (!item.name) return false

    if (EXCLUDED_PASTE_TYPES.some(type => item.name?.toLowerCase().includes(type))) return false

    const isAllowedAction = ['copy', 'cut', 'paste'].some(action => item.name?.startsWith(`gc.spread.${action}`))

    const isAllowedRowColumnAction =
      (item.name.includes('Row') || item.name.includes('Column')) && ['delete', 'insert'].some(action => item.name?.includes(action))

    return isAllowedAction || isAllowedRowColumnAction
  })

  const { appendColumnsAction, appendRowsAction } = createCustomContextMenuActions(workbook, onChange)

  const getContextMenuItems = () => {
    const filterMenuItem = (item: any, allowedActions: string[]) => {
      const isHeaderRowSelected = workbook
        .getActiveSheet()
        ?.getSelections()
        ?.some(selection => selection.row === 0 || (selection.rowCount > 1 && selection.row === 0))

      // Prevent row deletion/insertion operations on the header row.
      if (isHeaderRowSelected && (item.name?.includes('delete') || item.name?.includes('insert'))) return false

      if (EXCLUDED_PASTE_TYPES.some(type => item.name?.toLowerCase().includes(type))) return false

      return allowedActions.some(action => item.name?.includes(action))
    }

    return [
      ...defaultMenuItems.filter(item => filterMenuItem(item, ['copy', 'cut', 'paste'])),
      ...defaultMenuItems.filter(item => filterMenuItem(item, ['delete', 'insert'])),
      { ...appendColumnsAction, workArea: 'colHeader' },
      { ...appendColumnsAction, workArea: 'cornerHeader' },
      { ...appendRowsAction, workArea: 'rowHeader' },
      { ...appendRowsAction, workArea: 'cornerHeader' }
    ]
  }

  workbook.contextMenu.menuData = getContextMenuItems()

  // Update menu items whenever a selection changes.
  workbook.bind(GC.Spread.Sheets.Events.SelectionChanged, () => {
    workbook.contextMenu.menuData = getContextMenuItems()
  })
}
