import { WORKBOOK_OPTION_OVERRIDES, WORKSHEET_OPTION_OVERRIDES } from '../constants'
import { _Column, _Row } from '../SpreadsheetEditor'
import { addTypeComment } from './dataManagement'
import { isEmpty } from 'lodash'
import GC from '@mescius/spread-sheets'

// functions

export const applyColumnHeaderStyles = (sheet: GC.Spread.Sheets.Worksheet, startColumn: number, columnCount: number) => {
  const range = sheet.getRange(0, startColumn, 1, columnCount)
  const borderBottom = new GC.Spread.Sheets.LineBorder('black', GC.Spread.Sheets.LineStyle.thin)

  range.borderBottom(borderBottom)
  range.fontWeight('500')
}

export const initializeSheet = (sheet: GC.Spread.Sheets.Worksheet, columns: _Column[], rows?: _Row[]) => {
  stopSheetOperations(sheet)

  // Clear the entire sheet to remove any previous data.
  sheet.clear(0, 0, sheet.getRowCount(), sheet.getColumnCount(), GC.Spread.Sheets.SheetArea.viewport, GC.Spread.Sheets.StorageType.data)
  sheet.comments.clear(new GC.Spread.Sheets.Range(0, 0, sheet.getRowCount(), sheet.getColumnCount()))

  // Always ensure at least 26 columns (A-Z), or more if needed based on data.
  const totalColumnsNeeded = Math.max(26, Math.ceil(columns.length / 26) * 26)
  sheet.setColumnCount(totalColumnsNeeded)

  // Set total rows with tiered minimums in increments of 100 based on data size.
  const dataRowCount = (rows?.length || 0) + 1 // Add 1 to account for column headers.
  const nextTier = Math.ceil(dataRowCount / 100) * 100
  const totalRowsNeeded = Math.max(100, nextTier)
  sheet.setRowCount(totalRowsNeeded)

  // Set all cells to text format by default.
  const range = sheet.getRange(0, 0, totalRowsNeeded, totalColumnsNeeded)
  range.formatter('@')

  // Set column headers in the first row and add comments for field types.
  if (!isEmpty(columns)) {
    const headerValues = columns.map(column => column.name)

    sheet.setArray(0, 0, [headerValues])
    columns.forEach((column: _Column, index: number) => addTypeComment(sheet, index, column.type))
  }

  // Set row data starting from the second row (index 1) since the first row contains column headers.
  if (rows) {
    const values = rows.map((row: _Row) => columns.map((column: _Column) => row[column.name.toLowerCase().replace(/ /g, '_')] || ''))

    sheet.setArray(1, 0, values)
  }

  // Freeze the column headers and apply styles.
  sheet.frozenRowCount(1)
  applyColumnHeaderStyles(sheet, 0, totalColumnsNeeded)

  // Auto-adjust column width to fit content.
  for (let columnIndex = 0; columnIndex < totalColumnsNeeded; columnIndex++) {
    const currentWidth = sheet.getColumnWidth(columnIndex)
    sheet.autoFitColumn(columnIndex)
    const autoFitWidth = sheet.getColumnWidth(columnIndex)
    const newWidth = Math.min(autoFitWidth + 8, 800) // Add 8px padding and cap at 800px.

    if (newWidth < currentWidth) {
      sheet.setColumnWidth(columnIndex, currentWidth)
    } else {
      sheet.setColumnWidth(columnIndex, newWidth)
    }
  }

  sheet.clearSelection()

  startSheetOperations(sheet)
}

export const setWorkbookOptions = (workbook: GC.Spread.Sheets.Workbook) => {
  workbook.options = { ...workbook.options, ...WORKBOOK_OPTION_OVERRIDES, tabStripVisible: false }
}

export const setWorksheetOptions = (sheet: GC.Spread.Sheets.Worksheet, isReadOnly: boolean) => {
  sheet.options = {
    ...sheet.options,
    ...WORKSHEET_OPTION_OVERRIDES,
    isProtected: isReadOnly,
    protectionOptions: { ...WORKSHEET_OPTION_OVERRIDES.protectionOptions, allowEditObjects: !isReadOnly }
  }
}

const startSheetOperations = (sheet: GC.Spread.Sheets.Worksheet) => {
  // See: https://developer.mescius.com/spreadjs/docs/BestPractices
  sheet.resumeCalcService(false)
  sheet.resumeEvent()
  sheet.resumePaint()
}

const stopSheetOperations = (sheet: GC.Spread.Sheets.Worksheet) => {
  sheet.suspendPaint()
  sheet.suspendEvent()
  sheet.suspendCalcService(true)
}
