import { Alert, Box, Button, Chip, SxProps, Tooltip, Typography } from '@mui/material'
import { ExtractionFieldTypes } from '../ChecklistTab/SharedInputs/ExtractionFieldTypeInput'
import { FEEDBACK_PAGE_PATH } from '../../../../../FeedbackPage'
import { Features, Permissions, useUserAccess } from '../../../../../../hooks/useUserAccess'
import { FirstLookStatus, VerifiedSample } from '../../../../../../graphql/codegen/schemas'
import { KLARITY_BLUE } from '../../../../../../utils/styleUtils'
import { ModalOptions, useCciMainContext } from '../../../../CCI_Main'
import { MoreMenu, _MenuItem } from '../../../../../../components/MoreMenu'
import { NavLink } from 'react-router-dom'
import { Substatuses } from '../../../../../../utils/cci'
import { amber, blue, common, green, grey, lightGreen, red } from '@mui/material/colors'
import { formatDateTime } from '../../../../../../utils/datetimeUtils'
import { hideFileExtension } from '../../../../../../utils/stringUtils'
import { isEmpty, size } from 'lodash'
import {
  useFirstLookStatusOfLatestVerifiedSamplesByDocumentLazyQuery,
  useLatestVerifiedSamplesByDocumentLazyQuery,
  usePublishDataPointFieldMutation,
  useResetFeedbackMutation
} from '../../../../../../graphql/codegen/hooks'
import { usePrevious } from '../../../../../../hooks/usePrevious'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import CircularProgress from '@mui/material/CircularProgress'
import React, { FC, useCallback, useEffect, useMemo } from 'react'

// types

type _FirstLookStatusChipProps = { firstLookStatus?: FirstLookStatus | null; sx?: SxProps }
type _FirstLookStatusLabels = Record<FirstLookStatus, string>
type _VerifiedSamplesContentProps = { verifiedSampleList: VerifiedSample[] }
type _VerifiedSamplesFooterProps = { disabledMessage: string; isDisabled: boolean }
type _VerifiedSampleLinkProps = { verifiedSample: VerifiedSample }

// constants

const FIRST_LOOK_STATUS_LABELS: _FirstLookStatusLabels = {
  [FirstLookStatus.ErrorInvalidStatus]: 'Invalid Status',
  [FirstLookStatus.FeedbackAppliedReadyForReview]: 'Sample Ready for Review',
  [FirstLookStatus.FeedbackInProgress]: 'Feedback In Progress',
  [FirstLookStatus.FeedbackInProgressExtractionFailed]: 'Extraction Failed',
  [FirstLookStatus.FeedbackInProgressExtractionInProgress]: 'Pending Extraction',
  [FirstLookStatus.ReadyForReview]: 'Not Started',
  [FirstLookStatus.SampleIsCorrect]: 'Sample is Correct'
}

// components

export const FirstLookStatusChip: FC<_FirstLookStatusChipProps> = ({ firstLookStatus, sx }) => {
  const chipProps = useMemo(() => {
    switch (firstLookStatus) {
      case FirstLookStatus.ReadyForReview:
        return { bgcolor: grey[200], color: common.black }
      case FirstLookStatus.SampleIsCorrect:
        return { bgcolor: green[700], color: common.white }
      case FirstLookStatus.FeedbackInProgress:
        return { bgcolor: blue[100], color: common.black }
      case FirstLookStatus.FeedbackInProgressExtractionInProgress:
        return { bgcolor: amber[200], color: common.black }
      case FirstLookStatus.FeedbackInProgressExtractionFailed:
        return { bgcolor: red[100], color: common.black }
      case FirstLookStatus.FeedbackAppliedReadyForReview:
        return { bgcolor: lightGreen[300], color: common.black }
      default:
        return { bgcolor: red[100], color: common.black }
    }
  }, [firstLookStatus])

  if (!firstLookStatus) return null

  const { bgcolor, color } = chipProps

  return (
    <Chip
      label={FIRST_LOOK_STATUS_LABELS[firstLookStatus] || FIRST_LOOK_STATUS_LABELS.ERROR_INVALID_STATUS}
      size="small"
      sx={{ bgcolor, color, fontSize: 10, fontWeight: 600, height: 18, px: 0.5, ...sx }}
    />
  )
}

export const VerifiedSamples: FC = () => {
  const { refetchSelectedDataPointField, selectedItem } = useCciMainContext()

  const isInternalSource = selectedItem?.source === 'INTERNAL'
  const isPreAnnotationExtractionType = Boolean(selectedItem?.extraction_field_type?.includes(ExtractionFieldTypes['PRE-ANNOTATION']))

  const [getFirstLookStatuses, { data: firstLookStatusesData }] = useFirstLookStatusOfLatestVerifiedSamplesByDocumentLazyQuery({
    fetchPolicy: 'network-only',
    variables: { dataPointFieldId: selectedItem?.id }
  })

  const previousFirstLookStatusesData = usePrevious(firstLookStatusesData) as typeof firstLookStatusesData

  const [getLatestVerifiedSamplesByDocument, { data: verifiedSamplesData, error: verifiedSamplesError, loading: isVerifiedSamplesLoading }] =
    useLatestVerifiedSamplesByDocumentLazyQuery({ fetchPolicy: 'network-only', variables: { dataPointFieldId: selectedItem?.id } })

  const verifiedSampleList = useMemo(() => verifiedSamplesData?.latest_verified_samples_by_document || [], [verifiedSamplesData]) as VerifiedSample[]

  const isPublishButtonDisabled =
    isVerifiedSamplesLoading || selectedItem?.sub_status === Substatuses.PUBLISHING_IN_PROGRESS || selectedItem?.sub_status === Substatuses.PUBLISHED

  const disabledMessage =
    selectedItem?.sub_status === Substatuses.PUBLISHING_IN_PROGRESS
      ? 'Publishing in progress'
      : selectedItem?.sub_status === Substatuses.PUBLISHED
      ? 'There are no changes to publish'
      : ''

  useEffect(() => {
    if (isInternalSource && !isPreAnnotationExtractionType && selectedItem?.id) getLatestVerifiedSamplesByDocument()
  }, [getLatestVerifiedSamplesByDocument, isInternalSource, isPreAnnotationExtractionType, selectedItem?.id])

  useEffect(() => {
    if (selectedItem?.id) {
      const intervalId = setInterval(() => {
        const { latest_verified_samples_by_document } = firstLookStatusesData || {}

        size(latest_verified_samples_by_document) > size(verifiedSampleList) ? getLatestVerifiedSamplesByDocument() : getFirstLookStatuses()
      }, 5000)

      return () => clearInterval(intervalId)
    }
  }, [firstLookStatusesData, getFirstLookStatuses, getLatestVerifiedSamplesByDocument, selectedItem, verifiedSampleList])

  useEffect(() => {
    if (previousFirstLookStatusesData && firstLookStatusesData) {
      const previousData = previousFirstLookStatusesData.latest_verified_samples_by_document
      const currentData = firstLookStatusesData.latest_verified_samples_by_document

      if (JSON.stringify(previousData) !== JSON.stringify(currentData)) refetchSelectedDataPointField()
    }
  }, [firstLookStatusesData, previousFirstLookStatusesData, refetchSelectedDataPointField])

  return (
    <>
      <Box sx={{ flex: 1, p: 2.5 }}>
        {verifiedSamplesError ? (
          <Alert severity="error">An error occurred while loading sample values: {verifiedSamplesError.message}</Alert>
        ) : isVerifiedSamplesLoading ? (
          <VerifiedSamplesLoader />
        ) : isEmpty(verifiedSampleList) ? (
          <VerifiedSamplesEmpty />
        ) : (
          <VerifiedSamplesContent verifiedSampleList={verifiedSampleList} />
        )}
      </Box>

      <VerifiedSamplesFooter disabledMessage={disabledMessage} isDisabled={isPublishButtonDisabled} />
    </>
  )
}

const VerifiedSamplesContent: FC<_VerifiedSamplesContentProps> = ({ verifiedSampleList }) => {
  const sampleCount = size(verifiedSampleList)

  return (
    <Box sx={{ alignItems: 'stretch', display: 'flex', flexDirection: 'column', gap: 2.5, pb: 1.5, pt: 2.5 }}>
      <Box sx={{ display: 'inline-flex', gap: 1, pb: 1.5, pr: 3 }}>
        <AutoAwesomeIcon color="secondary" sx={{ fontSize: 18, mt: 0.125 }} />

        <Typography variant="body2">
          Based on your configuration, Klarity AI has extracted sample values for this checklist field from {sampleCount} document
          {sampleCount !== 1 && 's'}. Please review and verify their accuracy.{' '}
          <Typography component="span" sx={{ fontWeight: 600 }} variant="body2">
            Many sample values may initially be incorrect. This is normal and expected.
          </Typography>
        </Typography>
      </Box>

      {verifiedSampleList.map(verifiedSample => (
        <VerifiedSampleLink key={verifiedSample.id} verifiedSample={verifiedSample} />
      ))}
    </Box>
  )
}

const VerifiedSamplesEmpty: FC = () => (
  <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', gap: 2, py: 6 }}>
    <CircularProgress size={16} sx={{ mt: -3.5 }} />

    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, justifyContent: 'center' }}>
      <Typography variant="body2">Klarity AI is working on gathering sample values of this checklist field for you to review.</Typography>

      <Typography variant="body2">This may take some time. You may continue to wait or come back to review later.</Typography>
    </Box>
  </Box>
)

const VerifiedSamplesFooter: FC<_VerifiedSamplesFooterProps> = ({ disabledMessage, isDisabled }) => {
  const { openModal, refetchSelectedDataPointField, selectedItem, setIsModalOpen, setModalLoading, setModalLoadingMessage } = useCciMainContext()
  const [publishDataPointField, { loading: isPublishing }] = usePublishDataPointFieldMutation({ onCompleted: refetchSelectedDataPointField })
  const canDeleteFields = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.DELETE_FIELD })

  const lastPublishedDateTime = selectedItem?.last_published_at ? formatDateTime(new Date(`${selectedItem.last_published_at}Z`)) : null
  const lastUserToPublish = selectedItem?.last_published_by ? `${selectedItem.last_published_by.first_name} ${selectedItem.last_published_by.last_name}` : null

  const deleteFieldModal = useCallback(
    () =>
      openModal({
        content: { dataPointId: selectedItem.id, dataPointName: selectedItem.name },
        modalOption: ModalOptions.DELETE_FIELD
      }),
    [openModal, selectedItem]
  )

  const handleClick = useCallback(() => {
    if (selectedItem?.id) publishDataPointField({ variables: { dataPointFieldId: selectedItem.id } })
  }, [publishDataPointField, selectedItem])

  useEffect(() => {
    setIsModalOpen(isPublishing)
    setModalLoading(isPublishing)
    setModalLoadingMessage(isPublishing ? 'Publishing in progress…' : '')
  }, [isPublishing, setIsModalOpen, setModalLoading, setModalLoadingMessage])

  return (
    <Box
      sx={{
        borderTop: `1px solid ${grey[300]}`,
        display: 'flex',
        flexDirection: 'column',
        ml: -3,
        pb: 3,
        pt: 2.5,
        px: 3,
        width: 'calc(100% + 48px)'
      }}
    >
      <Box sx={{ alignItems: 'center', display: 'flex', gap: 2.5, ml: 'auto', width: '100%' }}>
        {selectedItem?.sub_status === Substatuses.PUBLISHING_IN_PROGRESS ? (
          <Typography variant="body2">Publishing is in progress…</Typography>
        ) : (
          lastPublishedDateTime && (
            <Typography sx={{ color: grey[600] }} variant="caption">
              Last published{lastUserToPublish ? ` by ${lastUserToPublish}` : ''} on {lastPublishedDateTime}
            </Typography>
          )
        )}

        <Box sx={{ alignItems: 'center', display: 'flex', gap: 2.5, width: '100%' }}>
          {canDeleteFields && (
            <Button color="error" onClick={deleteFieldModal} variant="text">
              Delete
            </Button>
          )}

          <Tooltip arrow placement="top" title={disabledMessage}>
            <Box component="span" sx={{ ml: 'auto' }}>
              <Button disabled={isDisabled} onClick={handleClick} variant="contained">
                Publish
              </Button>
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  )
}

const VerifiedSamplesLoader: FC = () => (
  <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', gap: 2, py: 6 }}>
    <CircularProgress size={16} sx={{ mt: -3.5 }} />

    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, justifyContent: 'center' }}>
      <Typography variant="body2">Loading sample values…</Typography>

      <Typography variant="body2">This usually takes a few seconds.</Typography>
    </Box>
  </Box>
)

const VerifiedSampleLink: FC<_VerifiedSampleLinkProps> = ({ verifiedSample }) => {
  const { refetchSelectedDataPointField } = useCciMainContext()
  const [resetFeedback, { loading: isBusy }] = useResetFeedbackMutation({ onCompleted: refetchSelectedDataPointField })

  const dataPointFieldId = verifiedSample?.data_point_field?.id
  const documentId = verifiedSample?.document?.id

  const handleClick = useCallback(() => {
    if (dataPointFieldId && documentId) resetFeedback({ variables: { dataPointFieldId, documentId } })
  }, [dataPointFieldId, documentId, resetFeedback])

  const documentName = verifiedSample?.document?.alias || verifiedSample?.document?.name
  const documentType = verifiedSample?.document?.document_type?.name
  const firstLookStatus = verifiedSample?.first_look_status

  const menuItemList: _MenuItem[] = useMemo(
    () => [
      {
        disabledMessage: 'No feedback has been given to this sample yet',
        isDisabled: firstLookStatus === FirstLookStatus.ReadyForReview,
        label: 'Reset sample feedback',
        onClick: handleClick
      }
    ],
    [firstLookStatus, handleClick]
  )

  const displayedDocumentName = `${documentType && `${documentType}: `}${hideFileExtension(documentName || '')}`

  return (
    <NavLink rel="noreferrer" target="_blank" to={`${FEEDBACK_PAGE_PATH}${documentId}?feedbackDataPointFieldId=${dataPointFieldId}`}>
      <Box
        sx={{
          alignItems: 'center',
          border: `1px solid ${grey[300]}`,
          borderRadius: 1,
          display: 'flex',
          gap: 2.5,
          justifyContent: 'space-between',
          mx: 3,
          pl: 2.5,
          pr: 1,
          py: 1.5,
          '&:hover': { bgcolor: `${KLARITY_BLUE}0a` }
        }}
      >
        <Typography noWrap sx={{ color: KLARITY_BLUE }} variant="body2">
          {displayedDocumentName}
        </Typography>

        <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
          <FirstLookStatusChip firstLookStatus={firstLookStatus} sx={{ cursor: 'pointer' }} />

          <MoreMenu isBusy={isBusy} menuItemList={menuItemList} />
        </Box>
      </Box>
    </NavLink>
  )
}
