import GC from '@mescius/spread-sheets'

// constants

export const EXCLUDED_PASTE_TYPES = ['special', 'format'] // Filter out all paste options except: "All", "Formulas", and "Values"

export const FORMULA_PREFIX = '='

export const MAX_TOOLBAR_HEIGHT = 300

export const MIN_TOOLBAR_HEIGHT = 54

export const RESIZABLE_HANDLE_HEIGHT = 4

export const STATUS_BAR_HEIGHT = 25

export const WORKBOOK_OPTION_OVERRIDES = {
  allowCopyPasteExcelStyle: false,
  allowDragHeaderToMove: GC.Spread.Sheets.AllowDragHeaderToMove.both,
  allowSheetReorder: false,
  allowUserDragDrop: true,
  allowUserDragFill: true,
  allowUserDragMerge: false,
  allowUserEditFormula: true,
  newTabVisible: false,
  tabEditable: false
}

export const WORKSHEET_OPTION_OVERRIDES = {
  clipBoardOptions: GC.Spread.Sheets.ClipboardPasteOptions.all,
  commentReadOnly: true,
  isProtected: true,
  protectionOptions: { allowResizeColumns: true, allowResizeRows: true }
}
