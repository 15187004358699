import { Box, TextField, Typography } from '@mui/material'
import { DEFAULT_DATE_OPTION_VALUE } from './FieldOptions'
import { DELETE, getFieldTypeLabel } from '../../../../../../../utils/cci'
import { Sources } from '../../ChecklistTab/CreateInputs/SourceInput'
import { grey } from '@mui/material/colors'
import { isEmpty, sortBy } from 'lodash'
import { useCciMainContext } from '../../../../../CCI_Main'
import { useChecklistConfigContext } from '../../../../ChecklistConfigProvider'
import { useConstRefValue } from '../../../../../../../hooks/useConstRefValue'
import { useFieldValuesContext } from '../FieldValuesProvider'
import { useIsProductionTenant } from '../../../../../../../hooks/useTenantType'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import css from './style.module.scss'

// types

export enum FieldTypeLabels {
  BOOLEAN = 'Boolean',
  CURRENCY = 'Currency',
  CURRENCY_TYPE = 'Currency Type',
  DATA_TABLE = 'Data Table',
  DATE = 'Date',
  DROPDOWN = 'Dropdown',
  DURATION = 'Duration',
  FLOAT = 'Number – Decimal',
  GEO_CITY = 'Geo – City',
  GEO_COUNTRY = 'Geo – Country',
  GEO_STATE = 'Geo – State',
  MULTI_SELECT_DROPDOWN = 'Dropdown – Multi-select',
  NUMBER = 'Number – Non-decimal',
  PERCENTAGE = 'Number – Percentage',
  TEXT = 'Text'
}

export enum FieldTypes {
  BOOLEAN = 'BOOLEAN',
  CURRENCY = 'CURRENCY',
  CURRENCY_TYPE = 'CURRENCY_TYPE',
  DATA_TABLE = 'DATA_TABLE',
  DATE = 'DATE',
  DROPDOWN = 'DROP_DOWN',
  DURATION = 'DURATION',
  FLOAT = 'FLOAT',
  GEO_CITY = 'GEO_CITY',
  GEO_COUNTRY = 'GEO_COUNTRY',
  GEO_STATE = 'GEO_STATE',
  MULTI_SELECT_DROPDOWN = 'MULTI_SELECT_DROP_DOWN',
  NUMBER = 'NUMBER',
  PERCENTAGE = 'PERCENTAGE',
  TEXT = 'TEXT'
}

// constants

const FIELD = 'field_type'

const LABEL = 'Field Type'

// components

export const FieldTypeInput: FC = () => {
  const { selectedItem } = useCciMainContext()
  const { actionTypeMap, isCreateView, isEditView } = useChecklistConfigContext()
  const { fieldValues, updateFieldValue } = useFieldValuesContext()

  const value = fieldValues?.field_type || selectedItem?.field_type
  const initialValue = useConstRefValue(value || '')
  const isProductionTenant = useIsProductionTenant()
  const isReadOnly = fieldValues?.source === Sources.MATCHING || selectedItem?.source === Sources.MATCHING

  const allowedFieldTypeList = useMemo(
    () => (fieldValues?.action_type_id && actionTypeMap[fieldValues.action_type_id].valid_field_types) || selectedItem?.action_type?.valid_field_types,
    [actionTypeMap, fieldValues, selectedItem]
  )

  const fieldTypeOptionList = useMemo(() => {
    const options = Object.entries(FieldTypes).map(([key, value]) => ({ label: FieldTypeLabels[key as keyof typeof FieldTypes], value }))

    return isEmpty(allowedFieldTypeList)
      ? sortBy(options, 'label')
      : sortBy(
          options.filter(option => allowedFieldTypeList.includes(option.value)),
          'label'
        )
  }, [allowedFieldTypeList])

  const handleChange = useCallback(
    (newValue: string) => updateFieldValue(FIELD, isCreateView ? newValue : !newValue || newValue === initialValue ? null : newValue),
    [updateFieldValue, initialValue, isCreateView]
  )

  useEffect(() => {
    const singleOption = fieldTypeOptionList.length === 1 ? fieldTypeOptionList[0].value : null

    // `isCreateView` in the next conditional prevents `updateFieldValue` from being called initially in the `isEditView` case.
    // If, in the future, we allow the user to change field type in edit view, we will need to update this logic.

    if (isCreateView && singleOption) {
      updateFieldValue(FIELD, singleOption)
    }
  }, [fieldTypeOptionList, isCreateView, updateFieldValue])

  useEffect(() => {
    // Reset dependant values when changing the field type.
    if (value !== initialValue) {
      updateFieldValue('default_value', null)
      updateFieldValue('deleted_options', null)
      updateFieldValue('options', null)
      updateFieldValue('value_format', value !== 'DATE' && initialValue === 'DATE' ? DELETE : value === 'DATE' ? DEFAULT_DATE_OPTION_VALUE : null)
    }
  }, [initialValue, updateFieldValue, value])

  if (isReadOnly) {
    return (
      <Box className={css.inputWrapper}>
        <Typography sx={{ fontSize: 14, fontWeight: 600, mr: 2, width: 164 }}>{LABEL}</Typography>

        <TextField
          disabled
          inputProps={{ sx: { fontSize: 14, '&.Mui-disabled': { color: grey[800], WebkitTextFillColor: `${grey[800]} !important` } } }}
          size="small"
          sx={{ fontSize: 14, '& .Mui-disabled': { backgroundColor: grey[100] } }}
          value={getFieldTypeLabel(value)}
        />
      </Box>
    )
  }

  return (
    <Box className={css.inputWrapper}>
      <Typography component="label" htmlFor="field-type-select-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        {LABEL}
      </Typography>

      <SelectInput
        id="field-type-select-input"
        isClearable
        isDisabled={fieldTypeOptionList.length === 1 || (isEditView && isProductionTenant)}
        onChange={(option: { value: string }) => handleChange(option?.value)}
        options={fieldTypeOptionList}
        placeholder={getFieldTypeLabel(value)}
        value={fieldTypeOptionList.length === 1 ? fieldTypeOptionList[0].value : value}
      />
    </Box>
  )
}
