import 'tippy.js/dist/tippy.css'
import { BiArrowToLeft } from 'react-icons/bi'
import { DataPoint } from '../../graphql/codegen/schemas'
import { DataPointFieldSources } from '../DatapointField/DataMatching'
import { IconButton, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { useAppContext } from '../../app'
import { useDataPointChangeLogQuery, useDataPointDetailQuery } from '../../graphql/codegen/hooks'
import { useHistory, useParams } from 'react-router-dom'
import AuditSection from './AuditSection'
import ChecklistSkeleton from '../DataPointPanel/ChecklistSkeleton'
import CommentEditor from './CommentEditor'
import InputRow from '../DatapointField/InputRow'
import RSkeleton from 'react-loading-skeleton'
import React, { CSSProperties, FC, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import Tippy from '@tippyjs/react'
import css from './style.module.scss'

// types

type _DataPointOverlayProps = { dealIsFinalized: boolean; setIsOpen: (opening: any) => void }

// components

const DataPointOverlay: FC<_DataPointOverlayProps> = ({ dealIsFinalized, setIsOpen }) => {
  const { setErrorMessage, setExtendedErrorMessage } = useAppContext()
  const [overlayHeight, setOverlayHeight] = useState('')
  const history = useHistory()

  const { dataPointId, dealId, documentId } = useParams<{ dataPointId: string; dealId?: string; documentId?: string }>()

  const { data: changeLogData, error: changeLogError, loading: isLoadingChangeLog } = useDataPointChangeLogQuery({ variables: { id: dataPointId } })
  const { data: detailData, error: detailError, loading: isLoadingDetail } = useDataPointDetailQuery({ variables: { id: dataPointId } })

  const changeLog = changeLogData?.data_points?.edges[0]?.node?.change_log
  const dataPoint = detailData?.data_points?.edges[0]?.node as DataPoint
  const { description, name, source } = dataPoint?.data_point_field || {}
  const isDataMatchingField = source === DataPointFieldSources.MATCHING

  const isLoading = isLoadingDetail || isLoadingChangeLog

  const error = useMemo(() => detailError || changeLogError, [detailError, changeLogError])

  useEffect(() => {
    setIsOpen(true)

    return () => setIsOpen(false)
  }, [setIsOpen])

  useEffect(() => {
    if (error) {
      const { message, stack } = error

      setErrorMessage(message)
      setExtendedErrorMessage(stack || message)
    }
  }, [error, setErrorMessage, setExtendedErrorMessage])

  // Ensure the fixed-position overlay is always the same height as the SplitPane container.
  useLayoutEffect(() => {
    const splitPane = document.querySelector('.SplitPane')

    if (!splitPane) return

    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { contentRect } = entry
        setOverlayHeight(`${contentRect.height}px`)
      }
    })

    observer.observe(splitPane)

    return () => observer.disconnect()
  }, [])

  return (
    <div className={css.overlay} id="dataPointOverlay" style={{ '--overlay-height': overlayHeight } as CSSProperties}>
      <div className={css.topBar}>
        <h3>{isLoading ? <RSkeleton width={300} /> : name}</h3>

        <Tippy content={<span className={css.tippyText}>Return to checklist</span>}>
          <IconButton
            aria-label="Return to checklist"
            onClick={() => history.push(`${dealId ? `/deals/${dealId}` : `/documents/${documentId}`}/checklist${history.location.search}`)}
            sx={{ color: grey[900], borderRadius: 0.5, fontSize: 12, mr: 0.5, padding: 0, svg: { height: 18, width: 18 }, '&:hover': { background: 'none' } }}
          >
            <BiArrowToLeft />
          </IconButton>
        </Tippy>
      </div>

      <div className={css.scrollBodyWrapper}>
        <div className={css.scrollBody}>
          {description && (
            <div className={css.description}>
              <p>{description}</p>
            </div>
          )}

          {!isDataMatchingField &&
            (isLoading ? (
              <ChecklistSkeleton />
            ) : dataPoint ? (
              dealIsFinalized && (
                <div className={css.singleInputWrapper}>
                  <InputRow ariaLabel={`Value of "${name}"`} dataPoint={dataPoint} dealIsFinalized />
                </div>
              )
            ) : (
              <Typography sx={{ p: 2, textAlign: 'center' }}>Field not found. Please ensure that the URL has been entered correctly.</Typography>
            ))}

          {!isLoading && dataPoint && <AuditSection dataPoint={{ ...dataPoint, change_log: changeLog }} dealIsFinalized={dealIsFinalized} />}
        </div>
      </div>

      {!dealIsFinalized && <CommentEditor />}
    </div>
  )
}

export default DataPointOverlay
